import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Table, Col, Form, Button, Input, InputGroup, FormGroup, Label, Container, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getSite, getContacts } from '../../actions';
import OpenIncidents from './../../components/incidents/open';
import IncidentsListGroup from './../../components/incidents/listGroup';
import RunsStream from '../../components/runs/stream';
import Breadcrumb from '../../components/breadcrumb';
import EditableLabel from '../../components/editableLabel';
import SiteSidebar from '../../components/siteSidebar';


function Contacts() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();
  const [ siteName, setSiteName ] = useState( '' );
  const [ contacts, setContacts ] = useState( [] );

  useEffect( () => {
    handleGet();
  }, [] );

  const handleGet = () => {
    getSite( portfolioId, siteId, ( err, res ) => {
        if( !err ) {
            setSiteName( res.data.name );
        }
    });
    getContacts( portfolioId, siteId, {}, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setContacts( res.data );
      }
    });

};


  return (
    <div className="App">
      <Header />


      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>

        <SiteSidebar />

        <main className="w-100 p-3">
            <h3>Contacts for {siteName}</h3>

            <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/contacts/create'}>Create a new contact</Link>

            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>E-mail</th>
                  <th>Receives notifications for</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map( contact => 
                  <tr>
                    <td>{contact.name}</td>
                    <td>{contact.email}</td>
                    <td>
                      {contact.prompts.map( a => <Link className="me-1" to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + a.promptId}><Badge>{a.promptName}</Badge></Link> )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>            

          </main>

          </div>

      
    </div>
  );
}

export default Contacts;
