import { useState, useEffect } from 'react';
import Header from './../../components/header';
import Prompt from './../../components/prompt';
import { Form, Button, Input, FormGroup, Label, Container, ListGroup, ListGroupItem } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getRun, getIncidents, patchRun } from '../../actions';
import SiteSidebar from '../../components/siteSidebar';

function Run() {

  const navigate = useNavigate();
  const { portfolioId, siteId, runId } = useParams();

  const [ prompt, setPrompt ] = useState( '' );
  const [ response, setResponse ] = useState( '' );
  const [ createdTs, setCreatedTs ] = useState( '' );
  const [ imageUrl, setImageUrl ] = useState( '' );
  const [ method, setMethod ] = useState( '' );
  const [ incidents, setIncidents ] = useState( [] );
  const [ promptId, setPromptId ] = useState( -1 );
  const [ cameraId, setCameraId ] = useState( -1 );
  const [ isTestCase, setIsTestCase ] = useState( 0 );

  useEffect( () => {
    handleGet();
  }, [] );

  const handleGet = () => {
    console.log( siteId );
    
    getRun( portfolioId, siteId, runId, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        //setIncidents( res.data );
        setPrompt( res.data.prompt );
        setPromptId( res.data.promptId );
        setCameraId( res.data.cameraId );
        setResponse( res.data.response );
        setCreatedTs( res.data.createdTs );
        setImageUrl( res.data.imageUrl );
        setMethod( res.data.method );
        setIsTestCase( res.data.isTestCase );
      } else {
        console.log( err );
      }
    });


    getIncidents( portfolioId, siteId, { runId }, ( err, res ) => {
      //console.log( portfolioId, siteId, { cameraId, promptId, status: status } );
      console.log( err, res );
      if( !err ) {
        setIncidents( res.data );
      }
    });

};

  const handlePatch = ( fields ) => {
    patchRun( portfolioId, siteId, runId, fields, ( err, res ) => {
      console.log( err, res );
      if( !err ) handleGet();
    });
  };


  return (
    <div className="App">
      <Header />
      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <SiteSidebar />
        <main className="w-100 p-3">

          <h3>Run {runId}: {prompt}</h3>

          <div className="mb-2">{(new Date( createdTs )).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})}</div>
          <div>
            <span className="me-2"><Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + promptId}>View prompt</Link></span>
            |
            <span className="ms-2"><Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId}>Detected on Camera {cameraId}</Link></span>
          </div>


          <div className="mt-2 shadow">
            <a href={imageUrl} target="_blank"><img src={imageUrl} className="frame-large" /></a>
          </div>


      <Container>
        {/*Response: {response}<br />*/}

          <div>
            <h3>Incidents</h3>
            {incidents.map( incident =>
              <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/incidents/' + incident.id}>{incident.id}</a>
            )}
            {incidents.length === 0 &&
              <div>No incidents were detected on this run.</div>
            }
          </div>

          <Form>
            <FormGroup switch>
              <Input checked={isTestCase == 1} type="switch" role="switch" onChange={ e => handlePatch({ isTestCase: 1 }) } />
              <Label check>Save this as a test case</Label>
            </FormGroup>
          </Form>

        <Prompt 
          portfolioId={portfolioId}
          siteId={siteId}
          imageUrl={imageUrl}
          prompt={prompt}
          method={method}
        />
      </Container>
      </main>
      </div>
      </div>
    
  );
}

export default Run;
