import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Row, Col, Form, Button, Input, FormGroup, Label, Container, Card, CardTitle, CardText, CardFooter, CardGroup, ListGroup, ListGroupItem, Badge, Nav, NavItem, NavLink, CardImg, CardBody, CardSubtitle } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { getSite, patchIncident, getCameras, getPrompts, getIncidents, getRuns } from '../../actions';
import { MdCheck, MdClose, MdWarning } from 'react-icons/md';
import IncidentCard from './card';

function OpenIncidents({ portfolioId, siteId, cameraId, promptId }) {

  const navigate = useNavigate();
  const location = useLocation();
  
  const [ incidents, setIncidents ] = useState( [] );
  const [ status, setStatus ] = useState( 'open' );

  useEffect( () => {
    handleGet();
  }, [portfolioId, siteId] );

  const handleGet = () => {
      
    getIncidents( portfolioId, siteId, { cameraId, promptId, status: 'open' }, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setIncidents( res.data );
      }
    });
  };

  const handlePatch = ( incidentId, fields ) => {
    patchIncident( portfolioId, siteId, incidentId, fields, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        handleGet();
      }
    });
  }

  return (
    <div>
      <p className="lead">Let's take a look at these {incidents.length} incidents</p>
      <Row>
          {incidents.map( incident =>
          <Col xs={12} sm={6} md={4} className="mb-4" style={{height: '480px'}}>
            <IncidentCard portfolioId={portfolioId} siteId={siteId} incident={incident} />
          </Col>
          )}
      </Row>
    </div>
  );
}

export default OpenIncidents;
