import axios from 'axios';
import _ from 'lodash';
import store from './store';

function resetSession() {
    if( store.getState().user.id !== -1 ) store.dispatch({ type: 'SET_USER', payload: { id: -1, username: '' } });
}

const axiosInstance = axios.create({ 
    baseURL: process.env.REACT_APP_API_HOST,
    withCredentials: true
});

axiosInstance.interceptors.response.use( 
    res => {
        return res;
    },
    err => {
        if( err?.response?.status === 403 ) {
            if( window.location.pathname !== '/login' ) window.location = '/login';
            return;
        }
        return Promise.reject( err );
    }
);


export function Register( orgName, orgZip, orgIsManager, orgIsOwner, email, username, password, confirm, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'users/register', { orgName, orgZip, orgIsManager, orgIsOwner, email, username, password, confirm } )
    .then( res => {
        store.dispatch({ type: 'SET_USER', payload: {...res.data} });
        if( typeof callback === 'function' ) callback( null, res.data );
        //const persons = res.data;
        //this.setState({ persons });
    })
    .catch( err => {
        console.log( err );
        if( err.response.status === 400 ) {
            callback( err.response.data, null );
        }
    });
}


export function getUser( callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'users/me', {} )
    .then( res => {
        store.dispatch({ type: 'SET_USER', payload: {...res.data} });
        if( typeof callback === 'function' ) callback( null, res.data );
        //const persons = res.data;
        //this.setState({ persons });
    })
    .catch( err => {
        if( _.has( err, 'response.status' ) && err.response.status === 403 ) {
            resetSession();
        } else {
            resetSession();
        }
    });
}

export function Login( username, password, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'users/login', { username, password } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res.data );
        //const persons = res.data;
        //this.setState({ persons });
    })
    .catch( err => {
        callback( err, null );
    });
}

export function Logout( callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'users/logout', {} )
    .then( res => {
        resetSession();
        if( typeof callback === 'function' ) callback( null, res.data );
        window.location.href = '/';
    });
}


export function patchUser( username, kvps, callback ) {
    axiosInstance.patch( process.env.REACT_APP_API_HOST + 'users/' + username, kvps )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res.data );
    })
    .catch( err => {
        callback( err, null );
        /*if( err.response.status === 400 ) {
            callback( err.response.data, null );
        }*/
    });
}


// Get list of users
export function getUsers( q, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'users?' + q )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        callback( err, null );
        /*if( err.response.status === 400 ) {
            callback( err.response.data, null );
        }*/
    });
}




export function createUser( username, password, confirm, email, name, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'users/create', { username, password, confirm, email, name } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res.data );
        //const persons = res.data;
        //this.setState({ persons });
    })
    .catch( err => {
        console.log( err );
        if( err.response.status === 400 ) {
            callback( err.response.data, null );
        }
    });
}



// ############################################################################
// Portfolios
// ############################################################################

export function getPortfolio( portfolioId, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getPortfolios( params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function createPortfolio( portfolio, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'portfolios', portfolio )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function patchPortfolio( portfolioId, patch, callback ) {
    axiosInstance.patch( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId, patch )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res.data );
    })
    .catch( err => {
        callback( err, null );
        /*if( err.response.status === 400 ) {
            callback( err.response.data, null );
        }*/
    });
}

// ############################################################################
// Sites
// ############################################################################

export function getSiteSnapshots( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/snapshots', { params: params } )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getSite( portfolioId, siteId, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getSites( portfolioId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function createSite( portfolioId, site, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites', site )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function patchSite( portfolioId, siteId, patch, callback ) {
    axiosInstance.patch( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId, patch )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res.data );
    })
    .catch( err => {
        callback( err, null );
        /*if( err.response.status === 400 ) {
            callback( err.response.data, null );
        }*/
    });
}


// ############################################################################
// Cameras
// ############################################################################


export function getCameraSnapshot( portfolioId, siteId, cameraId, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId + '/snapshot' )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getCamera( portfolioId, siteId, cameraId, callback ) {
    console.log( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId );
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getCameras( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getCameraCount( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/count', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function createCamera( portfolioId, siteId, camera, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras', camera )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function patchCamera( portfolioId, siteId, cameraId, fields, callback ) {
    axiosInstance.patch( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId, fields )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function deleteCamera( portfolioId, siteId, cameraId, callback ) {
    console.log( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId );
    axiosInstance.delete( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getCameraPromptAssociations( portfolioId, siteId, cameraId, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId + '/prompts' )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function createCameraPromptAssociation( portfolioId, siteId, cameraId, promptId, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId + '/prompts/' + promptId )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


export function deleteCameraPromptAssociation( portfolioId, siteId, cameraId, promptId, callback ) {
    axiosInstance.delete( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId + '/prompts/' + promptId )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function patchCameraPromptAssociation( portfolioId, siteId, cameraId, promptId, fields, callback ) {
    axiosInstance.patch( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId + '/prompts/' + promptId, fields )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

// ############################################################################
// Prompts
// ############################################################################

export function getPrompt( portfolioId, siteId, promptId, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + promptId )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getPrompts( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/prompts', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getPromptCount( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/count', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function patchPrompt( portfolioId, siteId, promptId, fields, callback ) {
    axiosInstance.patch( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + promptId, fields )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function createPrompt( portfolioId, siteId, prompt, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/prompts', prompt )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


export function getPromptCameraAssociations( portfolioId, siteId, promptId, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + promptId + '/cameras' )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getPromptContacts( portfolioId, siteId, promptId, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + promptId + '/contacts' )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


export function createPromptContactAssociation( portfolioId, siteId, promptId, contactId, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + promptId + '/contacts/' + contactId )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


export function deletePromptContactAssociation( portfolioId, siteId, promptId, contactId, callback ) {
    axiosInstance.delete( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + promptId + '/contacts/' + contactId )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


// ############################################################################
// Incidents
// ############################################################################

export function getIncidentCount( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/incidents/count', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getIncidents( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/incidents', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getIncident( portfolioId, siteId, incidentId, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/incidents/' + incidentId )
    .then( res => {
        console.log( res );
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


export function patchIncident( portfolioId, siteId, incidentId, fields, callback ) {
    axiosInstance.patch( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/incidents/' + incidentId, fields )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


// ############################################################################
// Runs
// ############################################################################

export function patchRun( portfolioId, siteId, runId, fields, callback ) {
    axiosInstance.patch( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/runs/' + runId, fields )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getRun( portfolioId, siteId, runId, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/runs/' + runId, {} )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getRuns( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/runs', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}

export function getRunsStatus( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/runs/status', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


export function stopRuns( portfolioId, siteId, params, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/runs/stop', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


// ############################################################################
// Direct prompts
// ############################################################################

export function getResponse( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/direct/', { params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}


// ############################################################################
// Contacts
// ############################################################################


export function createContact( portfolioId, siteId, fields, callback ) {
    axiosInstance.post( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/contacts', fields )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        if( typeof callback === 'function' ) callback( err, null );
    });
}


export function getContacts( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/contacts', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}




// ############################################################################
// Reports
// ############################################################################

export function getReport( portfolioId, siteId, params, callback ) {
    axiosInstance.get( process.env.REACT_APP_API_HOST + 'portfolios/' + portfolioId + '/sites/' + siteId + '/reports', { params: params } )
    .then( res => {
        if( typeof callback === 'function' ) callback( null, res );
    })
    .catch( err => {
        console.log( err );
        if( typeof callback === 'function' ) callback( err, null );
    });
}
