import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Col, Form, Button, Input, InputGroup, FormGroup, Label, Container, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getSite, patchSite, getCameras, getPrompts, getRuns } from '../../actions';
import OpenIncidents from './../../components/incidents/open';
import IncidentsListGroup from './../../components/incidents/listGroup';
import RunsStream from '../../components/runs/stream';
import Breadcrumb from '../../components/breadcrumb';
import EditableLabel from '../../components/editableLabel';
import SiteSidebar from '../../components/siteSidebar';
import { MdHome, MdSettings } from 'react-icons/md';


function Settings() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();

  const [ name, setName ] = useState( '' );
  const [ workerUrl, setWorkerUrl ] = useState( '' );

  useEffect( () => {
    handleGet();
  }, [] );

  const handleGet = () => {
    getSite( portfolioId, siteId, ( err, res ) => {
        if( !err ) {
            setName( res.data.name );
            setWorkerUrl( res.data.workerUrl );
            //setSites( res.data.sites );
        }
    });


};


  const handleUpdate = patch => {
    patchSite( portfolioId, siteId, patch, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
          handleGet();
            //setName( res.data.name );
            //setWorkerUrl( res.data.workerUrl );
            //setSites( res.data.sites );
        }
    });
  };


  return (
    <div className="App">
      <Header />


      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>

        <SiteSidebar />

        <main className="w-100 p-3">

          <h3><MdSettings className="mb-2" color="gray" /> Settings for {name}</h3>

          <Form className="border p-3 mb-3">
            <div className="row">
              <Label sm={2}>Site Name</Label>
              <Col sm={10}>
                <EditableLabel 
                  value={name}
                  onSave={ value => handleUpdate({ name: value }) }
                />
              </Col>
            </div>

            <div className="row">
              <Label sm={2}>Worker URL</Label>
              <Col sm={10}>
                <EditableLabel 
                  value={workerUrl}
                  onSave={ value => handleUpdate({ workerUrl: value }) }
                />
                <p>Make sure to include the https:// at the start and end the URL with a / character.</p>
              </Col>
            </div>
          </Form>


          </main>

          </div>

      
    </div>
  );
}

export default Settings;
