// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header-container {
    /*height: 10vh;
    overflow: hidden;*/
}

.header {
    margin: auto;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #ccc;
}

.header-logo {

}

.header-nav {
  flex: 1 1;
  margin-left: 20px;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":";AACA;IACI;sBACkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,8BAA8B;IAC9B,aAAa;IACb,6BAA6B;AACjC;;AAEA;;AAEA;;AAEA;EACE,SAAO;EACP,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":["\n.header-container {\n    /*height: 10vh;\n    overflow: hidden;*/\n}\n\n.header {\n    margin: auto;\n    display: flex;\n    flex-flow: row;\n    justify-content: space-between;\n    padding: 12px;\n    border-bottom: 1px solid #ccc;\n}\n\n.header-logo {\n\n}\n\n.header-nav {\n  flex: 1;\n  margin-left: 20px;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
