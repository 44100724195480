import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Form, Button, Input, FormGroup, Label, Container } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { createContact } from '../../actions';


function Create() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();

  const [ name, setName ] = useState( '' );
  const [ email, setEmail ] = useState( '' );

  const handleCreate = () => {
    createContact( portfolioId, siteId, { name, email }, ( err, res ) => {
      console.log( err, res );
      if( res.status === 200 ) {
        navigate( '/portfolios/' + portfolioId + '/sites/' + siteId + '/contacts' );
      }
    });
  }

  return (
    <div className="App">
      <Header />

      <Container>
        <Form>
          <FormGroup>
            <Label>Name</Label>
            <Input value={name} onChange={ e => setName( e.target.value ) } />
          </FormGroup>
          <FormGroup>
            <Label>E-mail</Label>
            <Input value={email} onChange={ e => setEmail( e.target.value ) } />
          </FormGroup>
          <Button onClick={handleCreate}>Create</Button>
        </Form>

      </Container>
      
    </div>
  );
}

export default Create;
