import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Form, Button, Input, FormGroup, Label, Container, ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getResponse } from '../../actions';

function Prompt(props) {

  const [ method, setMethod ] = useState( 'vqa' );
  const [ prompt, setPrompt ] = useState( '' );
  const [ quadrants, setQuadrants ] = useState( '' );
  const [ response, setResponse ] = useState( '' );
  const [ probability, setProbability ] = useState( 0 );
  const [ systemMessage, setSystemMessage ] = useState( '' );
  const [ isLoading, setIsLoading ] = useState( false );

  useEffect( () => {
    setPrompt( props.prompt );
  }, [ props.prompt ] );

  useEffect( () => {
    setMethod( props.method );
  }, [ props.method ] );


  const handlePrompt = () => {
    console.log( 'handlePrompt' );
    setIsLoading( true );
    getResponse( props.portfolioId, props.siteId, { imageUrl: props.imageUrl, prompt, method, quadrants, systemMessage }, ( err, res ) => {
        console.log( err, res );
        setIsLoading( false );
        if( !err ) {
            setResponse( res.data.response );
            setProbability( res.data.probability );
            //setName( res.data.name );
            //setWorkerUrl( res.data.workerUrl );
            //setSites( res.data.sites );
        }
    });
  }

  return (
    <div>    
        Prompt:<br />
        <Input value={prompt} onChange={ e => setPrompt( e.target.value )} /><br />
        Method:<br />
        <Input value={method} onChange={ e => setMethod( e.target.value )} /><br />
        Quadrants:<br />
        <Input value={quadrants} onChange={ e => setQuadrants( e.target.value )} /><br />
        System Message:<br />
        <Input value={systemMessage} onChange={ e => setSystemMessage( e.target.value )} /><br />
        
        <Button onClick={handlePrompt} disabled={isLoading}>Submit</Button>
        {isLoading && <span><Spinner /></span>}
        
        {response}
        {' '}
        {probability}
    </div>
  );
}

export default Prompt;
