import { Container } from "reactstrap";
import Header from "../../components/header";
import AdminSidebar from "./sitebar";
import { useEffect, useState } from "react";
import { getUsers } from "../../actions";


export default function Admin() {

    const [ users, setUsers ] = useState( [] );

    useEffect( () => {
        getUsers( {}, ( err, res ) => {
            console.log( err, res );
            if( !err ) setUsers( res.data );
        });
    }, [] );

    return (
        <div className="App">
            <Header />
            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                <AdminSidebar />
                <main className="w-100 p-3">        
                    <Container>
                        <h3>Administration</h3>
                        <hr />

                        {users.map( user =>
                            <div>{user.username}</div>
                        )}

                        <a href={'admin/users/create'}>Add user</a>

                    </Container>
                </main>
            </div>
        </div>
    );
}
