import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Form, Button, Input, FormGroup, Label, Container } from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getCamera, getPrompts, getCameraPromptAssociations, createCameraPromptAssociation, deleteCameraPromptAssociation, patchCamera, deleteCamera } from '../../actions';
import IncidentsListGroup from './../../components/incidents/listGroup';
import RunsListGroup from './../../components/runs/listGroup';
import Breadcrumb from '../../components/breadcrumb';
import SiteSidebar from '../../components/siteSidebar';
import Prompt from '../../components/prompt';


function Camera() {

  const navigate = useNavigate();
  const { portfolioId, siteId, cameraId } = useParams();

  const [ name, setName ] = useState( '' );
  const [ url, setUrl ] = useState( '' );
  const [ enabled, setEnabled ] = useState( false );
  const [ recentImageUrl, setRecentImageUrl ] = useState( '' );
  const [ prompts, setPrompts ] = useState( [] );
  const [ cameraPromptAssociations, setCameraPromptAssociations ] = useState( [] );

  useEffect( () => {
    handleGet();
  }, [portfolioId, siteId, cameraId] );

  const handleGet = () => {
    
    getCamera( portfolioId, siteId, cameraId, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        //setIncidents( res.data );
        setName( res.data.name );
        setUrl( res.data.url );
        setEnabled( res.data.enabled );
        setRecentImageUrl( res.data.recentImageUrl );
      }
    });

    getPrompts( portfolioId, siteId, {}, ( err, res ) => {
      if( !err ) {
        setPrompts( res.data );
      }
    });

    getCameraPromptAssociations( portfolioId, siteId, cameraId, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setCameraPromptAssociations( res.data );
      }
    });

  };


  const handleToggle = ( cameraId, promptId ) => {
    const exists = cameraPromptAssociations.find( a => a.cameraId == cameraId && a.promptId == promptId ) !== undefined;
    if( !exists ) {
      createCameraPromptAssociation( portfolioId, siteId, cameraId, promptId, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
          handleGet();
        }
      });
    } else {
      deleteCameraPromptAssociation( portfolioId, siteId, cameraId, promptId, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
          handleGet();
        }
      });        
    }
  };

  const handleDelete = () => {
    deleteCamera( portfolioId, siteId, cameraId, ( err, res ) => {
      if( !err ) {
        navigate( '/portfolios/' + portfolioId + '/sites/' + siteId );
      }
    });
  };

  const handlePatch = fields => {
    patchCamera( portfolioId, siteId, cameraId, fields, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        handleGet();
      }
    });
  };

  return (
    <div className="App">
      <Header />
      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <SiteSidebar />
        <main className="w-100 p-3">

          <Container>
            <h3>Camera {name}</h3>

            <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId + '/snapshot'}>Take a Snapshot</a>
            <img src={recentImageUrl} className="frame-medium" />

            <Prompt portfolioId={portfolioId} siteId={siteId} imageUrl={recentImageUrl} />

            <Form>
            <FormGroup>
                <Label>Name</Label>
                <Input value={name} onChange={ e => setName( e.target.value ) } />
              </FormGroup>
              <FormGroup>
                <Label>URL</Label>
                <Input value={url} onChange={ e => setUrl( e.target.value ) } />
              </FormGroup>

              <FormGroup switch>
                  <Input 
                    type="switch" 
                    role="switch" 
                    checked={enabled} 
                    onClick={ () => handlePatch({ enabled: !enabled }) }
                  />
                  <Label check>Enabled</Label>
                </FormGroup>            

            </Form>

            <a href={url} target="_blank">View Stream</a><br />

            <h4>Prompts Enabled</h4>
            <div>
              {prompts.map( prompt => 
                <FormGroup switch>
                  <Input 
                    type="switch" 
                    role="switch" 
                    checked={cameraPromptAssociations.find( a => a.cameraId == cameraId && a.promptId == prompt.id ) !== undefined} 
                    onClick={ () => handleToggle( cameraId, prompt.id ) }
                  />
                  <Label check><b>{prompt.name}</b>{' '}{prompt.prompt}</Label>
                </FormGroup>            
              )}
            </div>

            <IncidentsListGroup 
                portfolioId={portfolioId}
                siteId={siteId}
                cameraId={cameraId}
            />


            <RunsListGroup 
                portfolioId={portfolioId}
                siteId={siteId}
                cameraId={cameraId}
            />

            <Button color="danger" onClick={handleDelete}>Remove Camera</Button>
              

          </Container>
          
        </main>
      </div>


    </div>
  );
}

export default Camera;
