import { Button, Container, Spinner, Form, FormGroup, Row, Col, Input, Label } from 'reactstrap';
import Sidebar from '../../components/siteSidebar';
import Header from './../../components/header';
import { getCameras, getSiteSnapshots } from '../../actions';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';


export default function CameraSnapshot() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromTs = queryParams.get('fromTs');
  const toTs = queryParams.get('toTs');
  const cameraId = queryParams.get('cameraId');

  const [ isLoading, setIsLoading ] = useState( false );
  const [ cameras, setCameras ] = useState( [] );
  const [ localFromTs, setLocalFromTs ] = useState( fromTs ? utcToLocal( fromTs ) : '' );
  const [ localToTs, setLocalToTs ] = useState( toTs ? utcToLocal( toTs ) : '' );


  
  function utcToLocal(utcString) {
    try {
      const utcDate = new Date(utcString + " UTC"); // Parse as UTC
      const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
      const localString = localDate.toISOString().replace('T', ' ').substring(0, 19);
      //console.log( 'utcToLocal ', utcString, localString );
      return localString;  
    } catch( error ) {
      return null;
    }
    return '';
  }

  function localToUtc(localString) {
    try {
      const date = new Date(localString); 

      const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());

      const utcString = (new Date(utcDate)).toISOString().replace('T', ' ').substring(0, 19);
      return utcString;  
    } catch( error ) {
      return null;
    }
  }
  
  const setQueryParams = (newParams) => {
    // Parse current query params
    const currentParams = new URLSearchParams(location.search);

    // Merge new params, overriding duplicates
    Object.keys(newParams).forEach(key => {
      if( newParams[key] == null ) {
        currentParams.delete(key);
      } else {
        currentParams.set(key, newParams[key]);
      }
    });

    // Update the URL
    navigate({
      pathname: location.pathname,
      search: currentParams.toString()
    });
  };

  useEffect( () => {

    getCameras( portfolioId, siteId, {}, ( err, res ) => {
      if( !err ) {
        setCameras( res.data );
      }
    });
  
  }, [] );

  useEffect( () => {
    console.log( 'cameraId: ', cameraId );
    getSiteSnapshots( portfolioId, siteId, { cameraId }, ( err, res ) => {
      console.log( err, res );
    });

  }, [ cameraId ] );


  return (
    <div className="App">
      <Header />
      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <Sidebar />
        <main className="w-100 p-3">

          <Container>
            <h3>Snapshots</h3>

            <hr />


            <Form>
            <Row className="row-cols-lg-auto g-3 align-items-center">
              <Col>
                <FormGroup>
                  <Label></Label>
                  <Input size="sm" type="select" onChange={ e => setQueryParams({ cameraId: e.target.value === '-1' ? null : e.target.value }) }>
                    <option value={'-1'}>All cameras</option>
                    {cameras.map( camera =>
                      <option selected={camera.id == cameraId} value={camera.id}>{camera.name}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>

              <Col>
                  <FormGroup>
                    <Label>From</Label>
                    <Input size="sm" type="datetime-local" value={localFromTs} onChange={ e => setQueryParams({ fromTs: localToUtc( e.target.value.replace( 'T', ' ' ) + ':00' ) })} />
                  </FormGroup>
              </Col>

              <Col>
                  <FormGroup>
                    <Label>To</Label>
                    <Input size="sm" type="datetime-local" value={localToTs} onChange={ e => setQueryParams({ toTs: localToUtc( e.target.value.replace( 'T', ' ' ) + ':00' ) }) } />
                  </FormGroup>
              </Col>

              </Row>
            </Form>


            <Row>
                <Col>
                    
                </Col>
            </Row>

          </Container>
        </main>
      </div>
    </div>
  );
}
