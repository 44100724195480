import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Table, Col, Form, Button, Input, InputGroup, FormGroup, Label, Container, ListGroup, ListGroupItem, Badge, Spinner } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getSite, patchSite, getCameras, getPrompts, getRuns } from '../../actions';
import OpenIncidents from './../../components/incidents/open';
import IncidentsListGroup from './../../components/incidents/listGroup';
import RunsStream from '../../components/runs/stream';
import Breadcrumb from '../../components/breadcrumb';
import EditableLabel from '../../components/editableLabel';
import SiteSidebar from '../../components/siteSidebar';
import { MdSecurity } from 'react-icons/md';


function Prompts() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();

  const [ isLoading, setIsLoading ] = useState( false );
  const [ siteName, setSiteName ] = useState( '' );
  const [ prompts, setPrompts ] = useState( [] );

  useEffect( () => {
    handleGet();
  }, [] );

  const handleGet = () => {
    getSite( portfolioId, siteId, ( err, res ) => {
        if( !err ) {
            setSiteName( res.data.name );
        }
    });

    setIsLoading( true );
    getPrompts( portfolioId, siteId, {}, ( err, res ) => {
      setIsLoading( false );
      if( !err ) {
        setPrompts( res.data );
      }
    });

};


  return (
    <div className="App">
      <Header />


      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>

        <SiteSidebar />

        <main className="w-100 p-3">

          <div className="row border-bottom mb-2">
            <Col sm={6}>
              <h3>Prompts for {isLoading ? <Spinner /> : siteName}</h3>
            </Col>
            <Col sm={6} className="text-end">
              <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/create'}><Button size="sm">New prompt</Button></Link>
            </Col>
          </div>

          <FormGroup>
            <Table>
                <thead>
                  <tr>
                    <th className="text-center">Icon</th>
                    <th className="text-center">Enabled</th>
                    <th>Name</th>
                    {/*<th>Method</th>*/}
                    <th>Prompt</th>
                    <th className="text-center">Incidents</th>
                  </tr>
                </thead>
                <tbody>
                  {prompts.map( prompt =>
                    <tr>
                      <td className="text-center">
                        <MdSecurity />
                      </td>
                      <td className="text-center">{!prompt.enabled && <><Badge color="danger">Disabled</Badge>{' '}</>}</td>
                      <td><Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + prompt.id}>{prompt.name}</Link></td>
                      {/*<td>{prompt.method}</td>*/}
                      <td><Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + prompt.id}>{prompt.prompt}</Link></td>
                      <td className="text-center">
                        {prompt.openIncidents > 0 && <Badge>{prompt.openIncidents} Open incidents</Badge>}
                        {prompt.openIncidents == 0 && <>{prompt.openIncidents}</>}
                      </td>
                    </tr>
                  )}
                </tbody>
            </Table>
          </FormGroup>


          </main>

          </div>

      
    </div>
  );
}

export default Prompts;
