import { Button, Container, Spinner } from 'reactstrap';
import Sidebar from '../../components/siteSidebar';
import Header from './../../components/header';
import { getCamera, getCameraSnapshot } from '../../actions';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';


export default function CameraSnapshot() {

  const { portfolioId, siteId, cameraId } = useParams();
  const [ snapshotUrl, setSnapshotUrl ] = useState( '' );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ cameraName, setCameraName ] = useState( '' );


  useEffect( () => {
    getCamera( portfolioId, siteId, cameraId, ( err, res ) => {
      if( !err ) {
        setCameraName( res.data.name );
      }
    });
  }, [] );

  const handleGetSnapshot = () => {
    setIsLoading( true );
    getCameraSnapshot( portfolioId, siteId, cameraId, ( err, res ) => {
      setIsLoading( false );
      console.log( err, res );
      if( !err ) {
        setSnapshotUrl( res.data.snapshotUrl );
      }
    });
  };

  return (
    <div className="App">
      <Header />
      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <Sidebar />
        <main className="w-100 p-3">

          <Container>
            <h3>{cameraName}: Snapshot</h3>

            <hr />

            {!isLoading &&
              <Button color="primary" disabled={isLoading} onClick={handleGetSnapshot}>Take a snapshot</Button>
            }

            {isLoading &&
              <Spinner />
            }

            {snapshotUrl &&
              <div className="p-2 overflow-scroll">
                <img src={snapshotUrl} />
              </div>
            }


          </Container>
        </main>
      </div>
    </div>
  );
}
