import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Form, Button, Input, FormGroup, Label, Container, ListGroup, ListGroupItem, InputGroup } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getResponse } from '../../actions';
import { MdEdit, MdSave, MdCancel } from 'react-icons/md';


function EditableLabel(props) {

  const [ editing, setEditing ] = useState( false );
  const [ value, setValue ] = useState( props.value );

  useEffect( () => {
    setValue( props.value );
  }, [props.value] );
  
  return (
    <InputGroup>
        {!editing && <Button outline color="primary" onClick={ () => setEditing( true ) }><MdEdit /></Button>}
        <Input 
            value={value}
            readOnly={!editing}
            onChange={ e => setValue( e.target.value ) }
        />
        {editing && <Button onClick={ () => {props.onSave( value ); setEditing( false );} }><MdSave /></Button>}
        {editing && <Button onClick={ () => {setValue( props.value ); setEditing( false );} }><MdCancel /></Button>}
    </InputGroup>
  );
}

export default EditableLabel;
