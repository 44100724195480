import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Form, Button, Input, FormGroup, Label, Container, ListGroup, ListGroupItem } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getSite, patchSite, getCameras, getPrompts, getIncident } from '../../actions';
import './incidents.css';
import Prompt from '../../components/prompt';
import SiteSidebar from '../../components/siteSidebar';

function Incident() {

  const navigate = useNavigate();
  const { portfolioId, siteId, incidentId } = useParams();

  const [ promptId, setPromptId ] = useState( -1 );
  const [ prompt, setPrompt ] = useState( '' );
  const [ response, setResponse ] = useState( '' );
  const [ createdTs, setCreatedTs ] = useState( '' );
  const [ imageUrl, setImageUrl ] = useState( '' );
  const [ probability, setProbability ] = useState( 0 );
  const [ cameraId, setCameraId ] = useState( '' );
  const [ comment, setComment ] = useState( '' );

  const [ imagePrompt, setImagePrompt ] = useState( '' );

  useEffect( () => {
    handleGet();
  }, [] );

  const handleGet = () => {
    console.log( siteId );
    
    getIncident( portfolioId, siteId, incidentId, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        //setIncidents( res.data );
        setPromptId( res.data.promptId );
        setPrompt( res.data.prompt );
        setResponse( res.data.response );
        setCreatedTs( res.data.createdTs );
        setImageUrl( res.data.imageUrl );
        setProbability( res.data.probability );
        setCameraId( res.data.cameraId );
        setComment( res.data.comment );
      } else {
        console.log( err );
      }
    });

};


  const handleUpdate = patch => {
    console.log( siteId );
    patchSite( portfolioId, siteId, patch, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
            //setName( res.data.name );
            //setWorkerUrl( res.data.workerUrl );
            //setSites( res.data.sites );
        }
    });
  };


  return (
    <div className="App">
      <Header />
      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <SiteSidebar />
        <main className="w-100 p-3">

          <h3>Incident {incidentId}: {prompt}</h3>
          <div className="mb-2">{(new Date( createdTs )).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})}</div>
          <div>
            <span className="me-2"><Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + promptId}>View prompt</Link></span>
            |
            <span className="ms-2"><Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + cameraId}>Detected on Camera {cameraId}</Link></span>
          </div>

          <div className="mt-2 mb-2 border p-3">{comment}</div>

          <div className="mb-2 text-end">
            <Button color="success" outline>Confirm</Button>
            {' '}
            <Button outline>Close</Button>
            {' '}
            <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/incidents/' + incidentId + '/mislabeled'}><Button color="danger" outline>Mislabeled</Button></Link>
          </div>

          <div className="mt-2 shadow">
            <a href={imageUrl} target="_blank"><img src={imageUrl} className="frame-large" /></a>
          </div>


          {/*<Prompt portfolioId={portfolioId} siteId={siteId} imageUrl={imageUrl} />*/}
      
        </main>
      </div>
    </div>
  );
}

export default Incident;
