import { useEffect, useState } from "react";
import Header from "../../components/header";
import SiteSidebar from "../../components/siteSidebar";
import { getReport } from "../../actions";
import { useParams } from "react-router-dom";
import Plot from 'react-plotly.js';

export default function CountReport( props ) {

    const { portfolioId, siteId } = useParams();

    const [ data, setData ] = useState( {} );
    const [ dataDates, setDataDates ] = useState( [] );

    const queryParams = new URLSearchParams(location.search);
  

    useEffect( () => {
        getReport( portfolioId, siteId, { type: 'count' }, ( err, res ) => {
            console.log( err, res );
            if( !err ) {
                //let newData = { x: [], y: [] };
                let newData = {};
                let newDataDates = [];
                let allHours = [];
                for( let i = 0; i < res.data.length; i++ ) {
                    if( res.data[i].type === 'car') {
                        /*const formattedHour = ( new Date( res.data[i].hour + ' UTC' ) ).toLocaleString('en-US', {
                            weekday: 'short',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          });
                        newData.x.push( formattedHour );
                        newData.y.push( res.data[i].avg_count );*/
                        const formattedDate = (new Date(res.data[i].hour + ' UTC')).toLocaleString('en-US', {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        });
                        const formattedHour = (new Date(res.data[i].hour + ' UTC')).toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        });
                        if( !newData[formattedDate] ) newData[formattedDate] = { x: [], y: [] };
                        newData[formattedDate].x.push( formattedHour );                     
                        newData[formattedDate].y.push( res.data[i].avg_count );
                        if( newDataDates.indexOf( formattedDate ) === -1 ) newDataDates.push( formattedDate );
                        if( allHours.indexOf( formattedHour ) === -1 ) allHours.push( formattedHour );
                    }
                }
                // Make sure all data dates have all hours showing zero if none
                for( let i = 0; i < newDataDates.length; i++ ) {
                    for( let h = 0; h < allHours.length; h++ ) {
                        const d = newDataDates[i];
                        if( newData[d].x.indexOf( allHours[h] ) === -1 ) {
                            newData[d].x.push( allHours[h] );
                            newData[d].y.push( 0 );
                        }

                        let combined = newData[d].x.map((time, index) => {
                            return { time: time, value: newData[d].y[index] };
                        });
                        combined.sort((a, b) => new Date(`1970-01-01 ${a.time}`) - new Date(`1970-01-01 ${b.time}`));
                        newData[d].x = combined.map(item => item.time);
                        newData[d].y = combined.map(item => item.value);
                        
                    }
                }
                setData( newData );
                setDataDates( newDataDates );
                console.log( newData );
            }
        });
    }, [] );

    return (
        <div className="App">
        <Header />  
        <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
  
          <SiteSidebar />
          <main className="w-100 p-3">
  
                <h3>Count Report</h3>


                {dataDates.map( d => 
                    <div>
                        <Plot
                            data={[
                            {type: 'bar', x: data[d].x, y: data[d].y},
                            ]}
                            layout={ {width: 640, height: 320, title: d} }
                        />
                    </div>
                )}


          </main>
        </div>
        </div>
    );
}
