import React, { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row
} from 'reactstrap';


const daysOfWeek = [
    { name: 'Sunday', short: 'SU' },
    { name: 'Monday', short: 'MO' },
    { name: 'Tuesday', short: 'TU' },
    { name: 'Wednesday', short: 'WE' },
    { name: 'Thursday', short: 'TH' },
    { name: 'Friday', short: 'FR' },
    { name: 'Saturday', short: 'SA' }
  ];
  

const RecurrenceRule = () => {
  const [eventName, setEventName] = useState('');
  const [description, setDescription] = useState('');
  const [frequency, setFrequency] = useState('WEEKLY');
  const [interval, setInterval] = useState(1);
  const [selectedDays, setSelectedDays] = useState([]);
  const [bySetPos, setBySetPos] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');


  const handleDayToggle = (day) => {
    setSelectedDays((prev) =>
      prev.includes(day)
        ? prev.filter((d) => d !== day)
        : [...prev, day]
    );
  };  

  const handleSubmit = (e) => {
    e.preventDefault();
    const eventData = {
      eventName,
      description,
      frequency,
      interval,
      byDay: selectedDays.join(','),
      bySetPos,
      startTime,
      endTime,
      startDate,
      endDate
    };
    console.log(eventData);
    // Add your logic to handle form submission, e.g., sending data to your server
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>    
        <Row>
            <Col>
                <Label for="interval">Repeat every</Label>
            </Col>
            <Col>
                <Input
                    type="number"
                    id="interval"
                    value={interval}
                    onChange={(e) => setInterval(e.target.value)}
                    min="1"
                />
            </Col>
            <Col>
                <FormGroup>
                    <Input
                    type="select"
                    id="frequency"
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    >
                        <option value="DAILY">Daily</option>
                        <option value="WEEKLY">Weekly</option>
                        <option value="MONTHLY">Monthly</option>
                        {/*<option value="YEARLY">Yearly</option>*/}
                    </Input>
                </FormGroup>
            
            </Col>
        </Row>
      </FormGroup>
      {frequency === 'WEEKLY' &&
        <FormGroup>
            <Label>Repeat on</Label>
            <Row>
                {daysOfWeek.map((day) => (
                <Col key={day.short}>
                    <FormGroup check>
                        <Input
                            type="checkbox"
                            id={day.short}
                            checked={selectedDays.includes(day.short)}
                            onChange={() => handleDayToggle(day.short)}
                        />
                        <Label check>
                            {day.name}
                        </Label>
                    </FormGroup>
                </Col>
                ))}
            </Row>
        </FormGroup>
      }
      {frequency === 'MONTHLY' &&
        <FormGroup>
            <Row>
                <Col>
                    <Label>Repeat on the</Label>
                </Col>
                <Col>
                    <Input />
                </Col>
                <Col>
                    <Label>day of each month</Label>
                </Col>
            </Row>
        </FormGroup>
      }
      {/*<FormGroup>
        <Label for="bySetPos">Set Position (e.g., 1 for first, -1 for last)</Label>
        <Input
          type="number"
          id="bySetPos"
          value={bySetPos}
          onChange={(e) => setBySetPos(e.target.value)}
        />
      </FormGroup>*/}
      <FormGroup>
        <Row>
            <Col>From</Col>
            <Col>
                <Input
                    type="time"
                    id="startTime"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                />
            </Col>
            <Col>To</Col>
            <Col>
                <Input
                type="time"
                id="endTime"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                />
            </Col>
        </Row>
    </FormGroup>
      {/*<FormGroup>
        <Label for="startDate">Start Date</Label>
        <Input
          type="date"
          id="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
    </FormGroup>*/}
      <FormGroup>
        <Label for="endDate">End on</Label>
        <Input
          type="date"
          id="endDate"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </FormGroup>
      <Button type="submit">Create</Button>
    </Form>
  );
};

export default RecurrenceRule;