import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Form, Button, Input, FormGroup, Label, Container, FormText } from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { createCamera } from '../../actions';
import Sidebar from '../../components/siteSidebar';


function Create() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();

  const [ type, setType ] = useState( '' );
  const [ name, setName ] = useState( '' );
  const [ url, setUrl ] = useState( '' );
  const [ username, setUsername ] = useState( '' );
  const [ password, setPassword ] = useState( '' );
  const [ uid, setUid ] = useState( '' );
  const [ discovery, setDiscovery ] = useState( '' );
  const [ address, setAddress ] = useState( '' );

  const handleCreate = () => {
    createCamera( portfolioId, siteId, { type, name, url, username, password, uid, discovery, address }, ( err, res ) => {
      console.log( err, res );
      if( res.status === 200 ) {
        navigate( '/portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + res.data.cameraId );
      }
    });
  }

  return (
    <div className="App">
      <Header />
      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <Sidebar />
        <main className="w-100 p-3">

          <Container>
            <h3>Create a new camera</h3>
            <p>
              Please contact us at <a href="mailto:setup@groundskeeper.ai">setup@groundskeeper.ai</a> if you require setup assistance.
            </p>
            
            <Form>
            <FormGroup>
                <Label>Name</Label>
                <Input value={name} onChange={ e => setName( e.target.value ) } />
                <FormText>
                  A name for this camera.<br />
                  For example: Outdoor Northwest 1, Parking South 4.
                </FormText>
              </FormGroup>


              <FormGroup tag="fieldset">
                <FormGroup>
                  <Label>
                    Camera type
                  </Label>
                  <FormText>
                    <br />Select the type of camera.
                  </FormText>
                </FormGroup>
                <FormGroup check>
                  <Input
                    name="type"
                    type="radio"
                    checked={type === 'trial'}
                    onClick={ e => setType( 'trial' ) }
                  />
                  {' '}
                  <Label check>
                    Groundskeeper trial camera
                  </Label>
                  <FormText>
                    <br />Select this if you are using a Groundskeeper trial camera.
                  </FormText>
                </FormGroup>
                <FormGroup check>
                  <Input
                    name="type"
                    type="radio"
                    checked={type === 'rstp'}
                    onClick={ e => setType( 'rstp' ) }
                  />
                  {' '}
                  <Label check>
                    RSTP camera
                  </Label>
                  <FormText>
                    <br />Select this if you are using an RSTP camera.
                  </FormText>
                </FormGroup>
              </FormGroup>


              {type === 'trial' &&
                <>
                  <FormGroup>
                    <Label>Username</Label>
                    <Input value={username} onChange={ e => setUsername( e.target.value ) } />
                    <FormText>
                      Lorem ipsum
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input value={password} onChange={ e => setPassword( e.target.value ) } />
                    <FormText>
                      Lorem ipsum
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label>UID</Label>
                    <Input value={uid} onChange={ e => setUid( e.target.value ) } />
                    <FormText>
                      Lorem ipsum
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label>Discovery</Label>
                    <Input value={discovery} onChange={ e => setDiscovery( e.target.value ) } />
                    <FormText>
                      Lorem ipsum
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label>Address</Label>
                    <Input value={address} onChange={ e => setAddress( e.target.value ) } />
                    <FormText>
                      Lorem ipsum
                    </FormText>
                  </FormGroup>

                </>
              }

              {type === 'rstp' &&
                <FormGroup>
                  <Label>URL</Label>
                  <Input value={url} onChange={ e => setUrl( e.target.value ) } />
                  <FormText>
                    The URL to the camera stream.
                    In most cases, the camera URL should exist behind a private local network with no public external access.
                    The Groundskeeper.ai service worker must be able to access the camera's URL while still being accessible by the external Groundskeeper.ai API service.
                  </FormText>
                </FormGroup>
              }
              <Button onClick={handleCreate}>Create new camera</Button>
            </Form>

          </Container>
          
        </main>
      </div>
      
    </div>
  );
}

export default Create;
