import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Alert, Col, Form, Button, Input, FormGroup, Label, Container, Spinner } from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getPrompt, patchPrompt, getCameraCount, createCameraPromptAssociation, getPromptCameraAssociations, deleteCameraPromptAssociation } from '../../actions';
import IncidentsListGroup from './../../components/incidents/listGroup';
import RunsListGroup from './../../components/runs/listGroup';
import Breadcrumb from '../../components/breadcrumb';
import SiteSidebar from '../../components/siteSidebar';
import { MdSettings } from 'react-icons/md';


function Prompt() {

  const navigate = useNavigate();
  const { portfolioId, siteId, promptId } = useParams();

  const [ isLoading, setIsLoading ] = useState( false );
  const [ name, setName ] = useState( '' );
  const [ prompt, setPrompt ] = useState( '' );
  const [ method, setMethod ] = useState( '' );
  const [ enabled, setEnabled ] = useState( 0 );
  const [ cameraCount, setCameraCount ] = useState( 0 );
  const [ cameraPromptAssociations, setCameraPromptAssociations ] = useState( [] );

  useEffect( () => {
    handleGet();
  }, [portfolioId, siteId, promptId] );

  const handleGet = () => {
    
    setIsLoading( true );
    getPrompt( portfolioId, siteId, promptId, ( err, res ) => {
      setIsLoading( false );
      console.log( err, res );
      if( !err ) {
        setName( res.data.name );
        setPrompt( res.data.prompt );
        setMethod( res.data.method );
        setEnabled( res.data.enabled );
      }
    });

    getCameraCount( portfolioId, siteId, {}, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setCameraCount( res.data.count );
      }
    });

    getPromptCameraAssociations( portfolioId, siteId, promptId, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setCameraPromptAssociations( res.data );
      }
    });

  };



  return (
    <div className="App">
      <Header />

      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <SiteSidebar />
        <main className="w-100 p-3">

          <Container>

            <div className="row mt-2 mb-2">
              <Col xs={6}>
                <h3>Prompt: {isLoading ? <Spinner /> : name}</h3>
              </Col>
              <Col xs={6} className="text-end">
                <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + promptId + '/settings'}><Button color="primary" className="me-2" size="sm"><MdSettings /> Prompt Settings</Button></Link>
              </Col>
            </div>

            <hr />

            <Alert color="light">
              <p>This prompt is {enabled ? 'enabled' : 'disabled'}.</p>
              {enabled && <p className="mb-0">This prompt is running on {cameraPromptAssociations.length} of {cameraCount} cameras.</p>}
            </Alert>

            <div className="m-4">
              View this prompt's:
              {' '}
              <Link className="me-2" size="sm" outline><Button outline size="sm">Incidents</Button></Link>
              <Link className="me-2" size="sm" outline to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs?promptId=' + promptId}><Button outline size="sm">Runs</Button></Link>
            </div>

            <Form>
              {/*<FormGroup>
                <Label>Method</Label>
                <p className="lead">{method}</p>
              </FormGroup>*/}
              <FormGroup>
                <Label>Prompt</Label>
                <p className="lead">{prompt}</p>
              </FormGroup>
            </Form>

            <IncidentsListGroup 
                portfolioId={portfolioId}
                siteId={siteId}
                promptId={promptId}
            />
              

            {/*<RunsListGroup 
                portfolioId={portfolioId}
                siteId={siteId}
                promptId={promptId}
            />*/}


          </Container>
        </main>
      </div>
      
    </div>
  );
}

export default Prompt;
