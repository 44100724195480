import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Row, Col, Form, Button, Input, FormGroup, Label, Container, ListGroup, ListGroupItem, Badge, Nav, NavItem, NavLink } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { getSite, patchIncident, getCameras, getPrompts, getIncidents, getRuns } from '../../actions';


function IncidentsListGroup({ portfolioId, siteId, cameraId, promptId }) {

  const navigate = useNavigate();
  const location = useLocation();
  
  const [ incidents, setIncidents ] = useState( [] );
  const [ status, setStatus ] = useState( 'open' );

  useEffect( () => {
    const queryParams = new URLSearchParams(location.search);
    let nextStatus = queryParams.get('status');
    console.log( nextStatus );
    if( nextStatus === null ) nextStatus = 'open';
    if( nextStatus !== status ) setStatus( nextStatus );
  }, [location] );

  useEffect( () => {
    handleGet();
  }, [portfolioId, siteId] );

  const handleGet = () => {
      
    getIncidents( portfolioId, siteId, { cameraId, promptId }, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setIncidents( res.data );
      }
    });
  };

  const handlePatch = ( incidentId, fields ) => {
    patchIncident( portfolioId, siteId, incidentId, fields, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        handleGet();
      }
    });
  }

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink onClick={ () => navigate( location.pathname + '?status=open' )} active={status === 'open'}>Human Review Needed <Badge>{incidents.filter( incident => incident.status === 'open' ).length}</Badge></NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={ () => navigate( location.pathname + '?status=valid' )} active={status === 'valid'}>Valid <Badge>{incidents.filter( incident => incident.status === 'valid' ).length}</Badge></NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={ () => navigate( location.pathname + '?status=invalid' )} active={status === 'invalid'}>Invalid <Badge>{incidents.filter( incident => incident.status === 'invalid' ).length}</Badge></NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={ () => navigate( location.pathname + '?status=mislabeled' )} active={status === 'mislabeled'}>Mislabeled <Badge>{incidents.filter( incident => incident.status === 'mislabeled' ).length}</Badge></NavLink>
        </NavItem>
        <NavItem>
          <NavLink onClick={ () => navigate( location.pathname + '?status=closed' )} active={status === 'closed'}>Closed <Badge>{incidents.filter( incident => incident.status === 'closed' ).length}</Badge></NavLink>
        </NavItem>
      </Nav>
      <ListGroup>
          {incidents.filter( incident => incident.status === status ).map( incident =>
          <ListGroupItem>
              <div className="d-flex flex-row">
                <div class="p-2 bd-highlight">
                  <a href={incident.imageUrl} target="_blank">
                    <img src={incident.imageUrl} className="frame-medium" />
                  </a>
                </div>
                <div class="p-2 bd-highlight">
                  {(new Date( incident.createdTs )).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})}<br />
                  {incident.id}<br />
                  Prompt: {incident.prompt}<br />
                  Prediction: {incident.response}<br />
                  Probability: {Math.round( incident.probability * 100 )}%<br />
                  Camera ID: {incident.cameraId}<br />
                  <Button size="sm" onClick={ () => handlePatch( incident.id, { status: 'valid' } ) }>Confirm as valid</Button>
                  {' '}
                  <Button size="sm" onClick={ () => handlePatch( incident.id, { status: 'invalid' } ) }>Mark as invalid</Button>
                  {' '}
                  <Button size="sm" onClick={ () => handlePatch( incident.id, { status: 'closed' } ) }>Close</Button>
                  <br />
                  <br />
                  <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/incidents/' + incident.id}>View Incident</Link><br />
                  <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + incident.promptId}>Manage Prompt</Link><br />

                </div>
              </div>
          </ListGroupItem>
          )}
      </ListGroup>
    </div>
  );
}

export default IncidentsListGroup;
