import { Alert, Container, Row, Col, Button } from 'reactstrap';
import Header from './../../components/header';
import { useSelector } from 'react-redux';
import { MdCheck } from 'react-icons/md';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from 'react';
import { getPortfolios } from '../../actions';

function Home() {
  const user = useSelector( state => state.user );

  const [ portfolios, setPortfolios ] = useState( [] );

  useEffect( () => {
    const params = { query: '', page: 1 };
    getPortfolios( params, ( err, res ) => {
      console.log( err, res );
      if( !err ) setPortfolios( res.data );
    });
  }, [] );

  return (
    <div className="App">
      <Header />
      <Container>
        {/*<Row>
          <Alert color="danger">Cam2 failed to load on January 21, 2024 at 10:04 AM PT.</Alert>
          <Alert color="info">New incident at Windy Hills Shopping: Is there a cat?</Alert>
        </Row>*/}

        <Row>
          <Col className="m-2 p-2 shadow rounded">
            <Row className="text-center mt-2 mb-4">
              <h1 className="display-6">Welcome to Groundskeeper.ai</h1>
            </Row>

            {portfolios.map( portfolio => 
              <Row>
                <Col>
                  <a href={'/portfolios/' + portfolio.id}><h5>{portfolio.name}</h5></a>
                  <hr />
                  
                </Col>
              </Row>
            )}
          </Col>
        </Row>

      </Container>

    </div>
  );
}

export default Home;
