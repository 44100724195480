import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Row, Col, Form, Button, Input, FormGroup, Label, Container, Collapse, UncontrolledCollapse, FormText } from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getPrompt, patchPrompt, getCameras, createCameraPromptAssociation, getPromptCameraAssociations, deleteCameraPromptAssociation, patchCameraPromptAssociation, getContacts, getPromptContacts, createPromptContactAssociation, deletePromptContactAssociation } from '../../actions';
import IncidentsListGroup from './../../components/incidents/listGroup';
import RunsListGroup from './../../components/runs/listGroup';
import Breadcrumb from '../../components/breadcrumb';
import SiteSidebar from '../../components/siteSidebar';
import { MdSettings } from 'react-icons/md';
import RecurrenceRule from '../../components/recurrenceRules';


function Settings() {

  const navigate = useNavigate();
  const { portfolioId, siteId, promptId } = useParams();

  const [ generalIsOpen, setGeneralIsOpen ] = useState( true );
  const [ contactsIsOpen, setContactsIsOpen ] = useState( false );
  const [ camerasIsOpen, setCamerasIsOpen ] = useState( false );
  const [ scheduleIsOpen, setScheduleIsOpen ] = useState( false );

  const [ name, setName ] = useState( '' );
  const [ method, setMethod ] = useState( 'itm' );
  const [ prompt, setPrompt ] = useState( '' );
  const [ trigger, setTrigger ] = useState( '' );
  const [ triggerProbability, setTriggerProbability ] = useState( 1 );
  const [ interval, setInterval ] = useState( 60 );
  const [ cameraId, setCameraId ] = useState( -1 );
  const [ enabled, setEnabled ] = useState( 0 );
  const [ cameras, setCameras ] = useState( [] );
  const [ cameraPromptAssociations, setCameraPromptAssociations ] = useState( [] );
  const [ contacts, setContacts ] = useState( [] );
  const [ promptContacts, setPromptContacts ] = useState( [] );

  useEffect( () => {
    handleGet();
  }, [portfolioId, siteId, promptId] );

  const handleGet = () => {
    
    getPrompt( portfolioId, siteId, promptId, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setName( res.data.name );
        setMethod( res.data.method );
        setPrompt( res.data.prompt );
        setTrigger( res.data.trigger );
        setTriggerProbability( res.data.triggerProbability );
        setInterval( res.data.interval );
        setCameraId( res.data.cameraId );
        setEnabled( res.data.enabled );
      }
    });

    getCameras( portfolioId, siteId, {}, ( err, res ) => {
      if( !err ) {
        setCameras( res.data );
      }
    });

    getPromptCameraAssociations( portfolioId, siteId, promptId, ( err, res ) => {
      if( !err ) {
        setCameraPromptAssociations( res.data );
      }
    });

    getContacts( portfolioId, siteId, {}, ( err, res ) => {
      if( !err ) {
        setContacts( res.data );
      }
    });

    getPromptContacts( portfolioId, siteId, promptId, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setPromptContacts( res.data );
      }
    });

  };


    const handlePatch = ( promptId, fields ) => {
      patchPrompt( portfolioId, siteId, promptId, fields, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
          handleGet();
        }
      });
    }
 
    const handleToggle = ( cameraId, promptId ) => {
      const exists = cameraPromptAssociations.find( a => a.cameraId == cameraId && a.promptId == promptId ) !== undefined;
      if( !exists ) {
        createCameraPromptAssociation( portfolioId, siteId, cameraId, promptId, ( err, res ) => {
          console.log( err, res );
          if( !err ) {
            handleGet();
          }
        });
      } else {
        deleteCameraPromptAssociation( portfolioId, siteId, cameraId, promptId, ( err, res ) => {
          console.log( err, res );
          if( !err ) {
            handleGet();
          }
        });        
      }
    };

  const handleTogglePromptContact = ( promptId, contactId ) => {
    const exists = promptContacts.find( a => a.contactId == contactId && a.promptId == promptId ) !== undefined;
    if( !exists ) {
      createPromptContactAssociation( portfolioId, siteId, promptId, contactId, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
          handleGet();
        }
      });
    } else {
      deletePromptContactAssociation( portfolioId, siteId, promptId, contactId, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
          handleGet();
        }
      });
    }
  };

  const handleSetQuadrants = ( cameraId, promptId, quadrants ) => {
    console.log( cameraId, promptId, quadrants );
    patchCameraPromptAssociation( portfolioId, siteId, cameraId, promptId, { quadrants }, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        handleGet();
      }
    });
  }

  return (
    <div className="App">
      <Header />

      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <SiteSidebar />
        <main className="w-100 p-3">

          <Container>

            <h3>Prompt Settings: {name}</h3>
            <hr />


            <div>

                <div className="row mt-2 mb-2">
                    <Col xs={6}>
                        <h5>General Prompt Settings</h5>
                        <p>Manage general settings for this prompt.</p>
                    </Col>
                    <Col xs={6} className="text-end">
                        <Button className="me-2" size="sm" outline onClick={ () => setGeneralIsOpen( !generalIsOpen ) }><MdSettings className="mb-1" /> {generalIsOpen ? 'Collapse' : 'Expand'}</Button>
                    </Col>
                </div>

                <Collapse isOpen={generalIsOpen}>
                    <Form>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input value={name} onChange={ e => setName( e.target.value ) } />
                        </FormGroup>

                        <FormGroup tag="fieldset">
                          <FormGroup>
                            <Label>
                              Inference method
                            </Label>
                            <FormText>
                              <br />Select the type of inference to run against the camera stream.
                              Different types of detection objectives, camera image contents and camera image quality may perform better or worse with difference types of inference methods.
                            </FormText>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name="method"
                              type="radio"
                              checked={method === 'itm'}
                              onClick={ e => handlePatch( promptId, { method: 'itm' }) }
                            />
                            {' '}
                            <Label check>
                              Image-text matching
                            </Label>
                            <FormText>
                              <br />Write a statement about the camera stream and receive a probability score that the statement is true.<br />
                              For example, "There are skateboarders".
                            </FormText>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name="method"
                              type="radio"
                              checked={method === 'vqa'}
                              onClick={ e => handlePatch( promptId, { method: 'vqa' }) }
                            />
                            {' '}
                            <Label check>
                              Visual question and answer ("yes" or "no" only)
                            </Label>
                            <FormText>
                              <br />Ask a question about the camera stream and receive a "yes" or "no" answer.<br />
                              For example, "Are there skateboarders?"
                            </FormText>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name="method"
                              type="radio"
                              checked={method === 'llava'}
                              onClick={ e => handlePatch( promptId, { method: 'llava' }) }
                            />
                            {' '}
                            <Label check>
                              LLaVA ("yes" or "no" only)
                            </Label>
                            <FormText>
                              <br />Ask a question about the camera stream and receive a "yes" or "no" answer.<br />
                              For example, "Are there skateboarders?"
                            </FormText>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              name="method"
                              type="radio"
                              checked={method === 'sa2-clip'}
                              onClick={ e => handlePatch( promptId, { method: 'sa2-clip' }) }
                            />
                            {' '}
                            <Label check>
                              Segment Anything 2 and OpenCLIP
                            </Label>
                            <FormText>
                              <br />Identify the number of objects within an image. For example, "a police vehicle" or "skateboarder".
                            </FormText>
                          </FormGroup>
                        </FormGroup>

                        <FormGroup>
                            <Label>Prompt</Label>
                            <Input value={prompt} onChange={ e => setPrompt( e.target.value ) } />
                            <Button onClick={ () => handlePatch( promptId, { prompt: prompt } ) }>Save</Button>
                        </FormGroup>
                        <FormGroup>
                            <Label>Trigger</Label>
                            <Input value={trigger} onChange={ e => setTrigger( e.target.value ) } />
                        </FormGroup>
                        <FormGroup>
                            <Label>Trigger Probability</Label>
                            <Input value={triggerProbability} onChange={ e => setTriggerProbability( e.target.value ) } />
                            <Button onClick={ () => handlePatch( promptId, { triggerProbability: triggerProbability } ) }>Save</Button>
                        </FormGroup>
                        <FormGroup>
                            <Label>Interval</Label>
                            <Input value={interval} onChange={ e => setInterval( e.target.value ) } />
                        </FormGroup>
                        <FormGroup>
                            <Label>Camera ID</Label>
                            <Input value={cameraId} onChange={ e => setCameraId( e.target.value ) } />
                        </FormGroup>
                        <FormGroup switch>
                            <Input 
                            type="switch" 
                            role="switch" 
                            checked={enabled} 
                            onClick={ () => handlePatch( promptId, { enabled: enabled ? 0 : 1 } ) }
                            />
                            <Label check>This prompt is enabled</Label>
                        </FormGroup>
                    </Form>
                </Collapse>
            </div>

            <hr />

            <div>
              <div className="row mt-2 mb-2">
                  <Col xs={6}>
                      <h5>Schedule</h5>
                      <p>Choose the dates and times to run this prompt.</p>
                  </Col>
                  <Col xs={6} className="text-end">
                      <Button className="me-2" size="sm" outline onClick={ () => setScheduleIsOpen( !scheduleIsOpen ) }><MdSettings className="mb-1" /> {scheduleIsOpen ? 'Collapse' : 'Expand'}</Button>
                  </Col>
              </div>

              <Collapse isOpen={scheduleIsOpen}>
                <Form>
                  <FormGroup>
                    <Label>Schedule</Label>
                    <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/direct?promptId=' + promptId + '&cameraId='}><Button>Add Recurrence</Button></a>
                    <RecurrenceRule />
                  </FormGroup>
                </Form>
              </Collapse>
            </div>

            <hr />

            <div>
                <div className="row mt-2 mb-2">
                    <Col xs={6}>
                        <h5>Notifications</h5>
                        <p>Select which contacts to notify on trigger event for this prompt.</p>
                    </Col>
                    <Col xs={6} className="text-end">
                        <Button className="me-2" size="sm" outline onClick={ () => setContactsIsOpen( !contactsIsOpen ) }><MdSettings className="mb-1" /> {contactsIsOpen ? 'Collapse' : 'Expand'}</Button>
                    </Col>
                </div>

                <Collapse isOpen={contactsIsOpen}>
                    <Form>
                        <div>
                        {contacts.map( contact => 
                              <>
                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                  <Col>
                                    <FormGroup switch>
                                      <Input 
                                      type="switch" 
                                      role="switch" 
                                      checked={promptContacts.find( a => a.contactId == contact.id && a.promptId == promptId ) !== undefined} 
                                      onClick={ () => handleTogglePromptContact( promptId, contact.id ) }
                                      />
                                      <Label check>{contact.name} {contact.email}</Label>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                          )}
                        </div>
                    </Form>
                </Collapse>
            </div>

            <hr />

            <div>
                <div className="row mt-2 mb-2">
                    <Col xs={6}>
                        <h5>Cameras</h5>
                        <p>Select which cameras to run this prompt on.</p>
                    </Col>
                    <Col xs={6} className="text-end">
                        <Button className="me-2" size="sm" outline onClick={ () => setCamerasIsOpen( !camerasIsOpen ) }><MdSettings className="mb-1" /> {camerasIsOpen ? 'Collapse' : 'Expand'}</Button>
                    </Col>
                </div>

                <Collapse isOpen={camerasIsOpen}>
                    <Form>
                        <div>
                            {cameras.map( camera => 
                              <>
                                <Row className="row-cols-lg-auto g-3 align-items-center">
                                  <Col>
                                    <FormGroup switch>
                                      <Input 
                                      type="switch" 
                                      role="switch" 
                                      checked={cameraPromptAssociations.find( a => a.cameraId == camera.id && a.promptId == promptId ) !== undefined} 
                                      onClick={ () => handleToggle( camera.id, promptId ) }
                                      />
                                      <img src={camera.recentImageUrl} className="frame-small" />
                                      <Label check>{camera.name}</Label>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <Label for="Quadrants">
                                      Quadrants to analyze
                                    </Label>
                                  </Col>
                                  <Col>
                                    <Input
                                      type="select"
                                      onChange={ e => handleSetQuadrants( camera.id, promptId, e.target.value ) }
                                      value={cameraPromptAssociations.find( a => a.cameraId == camera.id && a.promptId == promptId ) !== undefined && cameraPromptAssociations.find( a => a.cameraId == camera.id && a.promptId == promptId ).quadrants}
                                    >
                                      <option>
                                        1
                                      </option>
                                      <option>
                                        2x2
                                      </option>
                                      <option>
                                        3x3
                                      </option>
                                      <option>
                                        4x4
                                      </option>
                                    </Input>
                                  </Col>
                                  <Col>
                                    <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/direct?promptId=' + promptId + '&cameraId=' + camera.id}>Run prediction</a>
                                  </Col>
                                </Row>
                              </>
                        )}
                        </div>
                    </Form>
                </Collapse>
            </div>

          </Container>
        </main>
      </div>
      
    </div>
  );
}

export default Settings;
