import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Table, Button, Input, FormGroup, Label, Container, Row, Col, Spinner } from 'reactstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { getPortfolios } from '../../actions';


function Portfolios() {

  const navigate = useNavigate();
  const [ portfolios, setPortfolios ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );

  useEffect( () => {
    setIsLoading( true );
    const params = { query: '', page: 1 };
    getPortfolios( params, ( err, res ) => {
      setIsLoading( false );
        if( !err ) setPortfolios( res.data );
    });
  }, [] );

  return (
    <div className="App">
      <Header />

      <Container>
        

        <Row>
          <Col xs={6}>
            <h3>Portfolios in your organization</h3>
          </Col>
          <Col xs={6} className="text-end">
            <Link to="/portfolios/create"><Button outline>Create a new portfolio</Button></Link>
          </Col>
        </Row>


        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {isLoading &&
              <tr>
                <td><Spinner /></td>
                <td><Spinner /></td>
              </tr>
            }
            {portfolios.map( portfolio =>
              <tr>
                <td>{portfolio.id}</td>
                <td><Link to={'/portfolios/' + portfolio.id}>{portfolio.name}</Link></td>
              </tr>
            )}
          </tbody>
        </Table>

      </Container>
      
    </div>
  );
}

export default Portfolios;
