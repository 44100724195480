import React from 'react';
import { Alert, Container, Row, Col, Card, CardBody, Form, Input, Label, InputGroup, FormGroup, Button } from 'reactstrap';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getUser, Register as HandleRegister } from '../../actions';

class RegisterForm  extends React.Component {
  constructor( props ){
    super( props );
    this.state = {
        orgName: '',
        orgZip: '',
        orgIsManager: true,
        orgIsOwner: false,
        username: '',
        password: '',
        confirm: '',
        error: '',
        email: '',
    };
    this.refresh = this.refresh.bind( this );
    this.animationInstance = null;
    this.handleRegister = this.handleRegister.bind( this );
    }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    getUser();
  }

  handleRegister() {
    this.setState({ error: '' }, () => {
        console.log( this.state );
        HandleRegister( this.state.orgName, this.state.orgZip, this.state.orgIsManager, this.state.orgIsOwner, this.state.email, this.state.username, this.state.password, this.state.confirm, ( err, res ) => {
            if( err !== null ) {
                this.setState({ error: err.error });
            } else {
                //GetUser();
                //this.props.setMode( 'login' );
                //this.props.navigate( '/' );
                window.location.href = '/';
            }
        });    
    });
  }

  render() {
    return (
      
        <Form>
            <h3>Create a Free Account</h3>

            <div class="form-outline mb-3 mt-4">
                <Input 
                    type="text"
                    placeholder="Company name" 
                    onChange={ e => this.setState({ orgName: e.target.value }) }
                />
            </div>

            <div class="form-outline mb-3">
                <Input 
                    type="text"
                    placeholder="Company ZIP code" 
                    onChange={ e => this.setState({ orgZip: e.target.value }) }
                />
            </div>

            <div class="form-outline mb-3">
                <FormGroup check>
                    <Input type="checkbox" checked={this.state.orgIsManager} onChange={ e => this.setState({ orgIsManager: e.target.checked }) } />
                    {' '}
                    <Label check>
                    My company is a property manager
                    </Label>
                </FormGroup>
            </div>

            <div class="form-outline mb-3">
                <FormGroup check>
                    <Input type="checkbox" checked={this.state.orgIsOwner} onChange={ e => this.setState({ orgIsOwner: e.target.checked }) } />
                    {' '}
                    <Label check>
                    My company owns one or more properties
                    </Label>
                </FormGroup>
            </div>

            <hr />

            <div class="form-outline mb-3">
                <Input 
                    type="email"
                    placeholder="Email address" 
                    onChange={ e => this.setState({ email: e.target.value }) }
                />
            </div>

            <div class="form-outline mb-3">
                <Input
                    type="text"
                    placeholder="Username" 
                    onChange={ e => this.setState({ username: e.target.value }) }
                />
            </div>

            <div class="form-outline mb-3">
                <Input 
                    type="password"
                    placeholder="Password" 
                    onChange={ e => this.setState({ password: e.target.value }) }
                />
            </div>

            <div class="form-outline mb-3">
                <Input 
                    type="password"
                    placeholder="Confirm password" 
                    onChange={ e => this.setState({ confirm: e.target.value }) }
                    onKeyPress={ e => { if( e.key === 'Enter' ) this.handleRegister(); } }
                />
            </div>

            {this.state.error !== '' &&
                <Alert color="primary">{this.state.error}</Alert>
            }

            <div class="clearfix text-lg-start mt-4 mb-4 pt-2">
                <Button className="float-end" type="button" color="primary" style={{paddingLeft: '2.5rem', paddingRight: '2.5rem'}} onClick={this.handleRegister}>Create</Button>
            </div>

            <hr />

            <p class="small mt-4 pt-1 mb-0 text-center">
                Already have an account? <br/>
                <Button onClick={ () => this.props.setMode( 'login' ) } type="button" color="primary" style={{paddingLeft: '2.5rem', paddingRight: '2.5rem'}}>Login</Button>
            </p>
        </Form>
    

    );

  }
}

export default RegisterForm;
