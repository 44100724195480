import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Row, Col, Form, Button, Input, FormGroup, Label, Container, ListGroup, ListGroupItem, Badge, Nav, NavItem, NavLink } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { getSite, getCameras, getPrompts, getRuns } from '../../actions';


function RunsListGroup({ portfolioId, siteId, cameraId, promptId }) {

  const navigate = useNavigate();
  const location = useLocation();
  
  const [ runs, setRuns ] = useState( [] );
  const [ status, setStatus ] = useState( 'open' );

  useEffect( () => {
    const queryParams = new URLSearchParams(location.search);
    const nextStatus = queryParams.get('status');
    if( nextStatus !== status ) setStatus( nextStatus );
  }, [location] );

  useEffect( () => {
    handleGet();
  }, [portfolioId, siteId] );

  const handleGet = () => {
      
    getRuns( portfolioId, siteId, { cameraId, promptId }, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setRuns( res.data );
      }
    });
  };

  return (
    <div>
      <h3>Runs</h3>
      <ListGroup>
          {runs.map( run =>
          <ListGroupItem>
              <div className="d-flex flex-row">
                <div class="p-2 bd-highlight">
                  <img src={run.imageUrl} className="frame-small" /><br />
                </div>
                <div class="p-2 bd-highlight">
                  {run.minutesElapsed} minutes ago<br />
                  {(new Date( run.createdTs )).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})}<br />
                  {run.id}<br />
                  <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs/' + run.id}>{run.prompt}</Link><br />
                  Response: {run.response}<br />
                  Probability: {Math.round( run.probability * 100 )}%<br />
                  Camera ID: {run.cameraId}
                </div>
              </div>
          </ListGroupItem>
          )}
      </ListGroup>
    </div>
  );
}

export default RunsListGroup;
