import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Row, Col, Form, Button, Input, FormGroup, Label, Container, Card, CardTitle, CardText, CardFooter, CardGroup, ListGroup, ListGroupItem, Badge, Nav, NavItem, NavLink, CardImg, CardBody, CardSubtitle } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { getSite, patchIncident, getCameras, getPrompts, getIncidents, getRuns } from '../../actions';
import { MdCheck, MdClose, MdWarning } from 'react-icons/md';
import { formatStatus } from '../../utils';


function IncidentCard( props ) {

  const navigate = useNavigate();

  const incident = props.incident;
  const portfolioId = props.portfolioId;
  const siteId = props.siteId;

  const handlePatch = ( incidentId, fields ) => {
    patchIncident( portfolioId, siteId, incidentId, fields, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
      }
    });
  }

  return (
    <Card className="h-100">
      <ul className="list-group list-group-flush">
        <li className="list-group-item p-1"><Badge>{formatStatus( incident.status )}</Badge></li>
      </ul>

      <a href={incident.imageUrl} target="_blank">
        <CardImg 
          className="rounded-0"
          src={incident.imageUrl}
          top
          height="180px"
        />
      </a>

      <CardBody>
        <CardTitle tag="h5">
          <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/incidents/' + incident.id}>{incident.id}: {incident.prompt}</Link>
        </CardTitle>
        <CardSubtitle>
          <small className="text-muted">
            {(new Date( incident.createdTs )).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})}                   
          </small>
        </CardSubtitle>
        <CardText className="mt-2 lh-sm overflow-hidden" style={{height: '4em'}}>
          {incident.comment}
        </CardText>
      </CardBody>

      <CardFooter className="text-center bg-white">
        <Button color="success" outline size="sm" onClick={ () => handlePatch( incident.id, { status: 'valid' } ) }><MdCheck /> Confirm</Button>
        {' '}
        <Button outline size="sm" onClick={ () => handlePatch( incident.id, { status: 'closed' } ) }><MdClose /> Close</Button>
        {' '}
        {/*<Button color="danger" outline size="sm" onClick={ () => handlePatch( incident.id, { status: 'mislabeled' } ) }><MdWarning /> Mislabeled</Button>*/}
        <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/incidents/' + incident.id + '/mislabeled'}><Button color="danger" outline size="sm"><MdWarning /> Mislabeled</Button></Link>
      </CardFooter>
    </Card>
  );
}

export default IncidentCard;
