import React from 'react';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getUser } from '../../actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

class Login  extends React.Component {
  constructor( props ){
    super( props );
    this.state = {
        username: '',
        password: '',
        confirm: '',
        mode: 'login',
        error: '',
        email: '',
};
    this.refresh = this.refresh.bind( this );
    this.animationInstance = null;
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate() {
      if( this.props.user.id !== -1 ) {
        this.props.navigate( '/' );
      }
    }

  refresh() {
    getUser();
  }


  render() {
    return (
      
        <section class="vh-100">
            <div class="container-fluid h-75">
                <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-md-9 col-lg-6 col-xl-5">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                    class="img-fluid" alt="Sample image"/>
                </div>
                <div className="App-login col-md-8 col-lg-6 col-xl-4 offset-xl-1">

                    {this.state.mode === 'login' &&
                        <LoginForm
                            setMode={ mode => this.setState({ mode: mode }) }
                        />
                    }
                    {this.state.mode === 'register' &&
                        <RegisterForm
                           setMode={ mode => this.setState({ mode: mode }) }
                        />
                }

                </div>
                </div>
            </div>
            <div
                class="fixed-bottom d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-indigo">
                <div class="text-white mb-3 mb-md-0">
                Copyright © 2020 Groundskeeper.ai. All rights reserved.
                </div>

                <div>
                <a href="#!" class="text-white me-4">
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a href="#!" class="text-white me-4">
                    <i class="fab fa-twitter"></i>
                </a>
                <a href="#!" class="text-white me-4">
                    <i class="fab fa-google"></i>
                </a>
                <a href="#!" class="text-white">
                    <i class="fab fa-linkedin-in"></i>
                </a>
                </div>
            </div>
        </section>

    );

  }
}

const mapStateToProps = ( state, ownProps ) => {
    return { user: state.user };
};

const mapDispatchToProps = dispatch => ({
    dispatch
});

export const withRouter = (Component) => {
    const Wrapper = (props) => {
      const navigate = useNavigate();
      
      return (
        <Component
          navigate={navigate}
          {...props}
          />
      );
    };
    
    return Wrapper;
  };

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Login ) );
