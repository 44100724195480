import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Nav, NavItem, NavLink, Row, Col, Form, Button, Input, InputGroup, FormGroup, Label, Container, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { getSite, getIncidents } from '../../actions';
import OpenIncidents from './../../components/incidents/open';
import IncidentsListGroup from './../../components/incidents/listGroup';
import RunsStream from '../../components/runs/stream';
import Breadcrumb from '../../components/breadcrumb';
import EditableLabel from '../../components/editableLabel';
import SiteSidebar from '../../components/siteSidebar';
import IncidentCard from '../../components/incidents/card';

function Incidents() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const promptId = queryParams.get('promptId');
  const cameraId = queryParams.get('cameraId');

  const [ siteName, setSiteName ] = useState( '' );
  const [ incidents, setIncidents ] = useState( [] );
  const [ status, setStatus ] = useState( 'open' );


  useEffect( () => {
    const queryParams = new URLSearchParams(location.search);
    let nextStatus = queryParams.get('status');
    console.log( nextStatus );
    if( nextStatus === null ) nextStatus = 'open';
    if( nextStatus !== status ) setStatus( nextStatus );
  }, [location] );

  useEffect( () => {
    handleGet();
  }, [] );

  const handleGet = () => {
    getSite( portfolioId, siteId, ( err, res ) => {
        if( !err ) {
            setSiteName( res.data.name );
        }
    });

    getIncidents( portfolioId, siteId, { cameraId, promptId }, ( err, res ) => {
      console.log( portfolioId, siteId, { cameraId, promptId, status: status } );
      console.log( err, res );
      if( !err ) {
        setIncidents( res.data );
      }
    });
  };

  console.log( incidents.filter( incident => incident.status === status ) );

  return (
    <div className="App">
      <Header />


      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>

        <SiteSidebar />

        <main className="w-100 p-3">
          <h3>Incidents for {siteName}</h3>

          <Nav tabs className="mb-4">
            <NavItem>
              <NavLink onClick={ () => navigate( location.pathname + '?status=open' )} active={status === 'open'}>Human Review Needed <Badge>{incidents.filter( incident => incident.status === 'open' ).length}</Badge></NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={ () => navigate( location.pathname + '?status=valid' )} active={status === 'valid'}>Valid <Badge>{incidents.filter( incident => incident.status === 'valid' ).length}</Badge></NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={ () => navigate( location.pathname + '?status=invalid' )} active={status === 'invalid'}>Invalid <Badge>{incidents.filter( incident => incident.status === 'invalid' ).length}</Badge></NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={ () => navigate( location.pathname + '?status=mislabeled' )} active={status === 'mislabeled'}>Mislabeled <Badge>{incidents.filter( incident => incident.status === 'mislabeled' ).length}</Badge></NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={ () => navigate( location.pathname + '?status=closed' )} active={status === 'closed'}>Closed <Badge>{incidents.filter( incident => incident.status === 'closed' ).length}</Badge></NavLink>
            </NavItem>
          </Nav>

          <div>
            <Row>
              {incidents.filter( incident => incident.status === status ).map( incident =>
                <Col xs={12} sm={6} md={4} className="mb-4" style={{height: '480px'}}>
                  <IncidentCard portfolioId={portfolioId} siteId={siteId} incident={incident} />
                </Col>
              )}
            </Row>
          </div>

          {/*<IncidentsListGroup
            portfolioId={portfolioId}
            siteId={siteId}
          />*/}


          </main>

          </div>

      
    </div>
  );
}

export default Incidents;
