import { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaRunning } from 'react-icons/fa';
import { MdArrowLeft, MdArrowRight, MdBarChart, MdCalendarMonth, MdCalendarToday, MdCalendarViewWeek, MdCamera, MdCameraAlt, MdExpand, MdExpandMore, MdHome, MdPeople, MdReport, MdSearch, MdSettings, MdTableChart, MdTimeline, MdTimer } from 'react-icons/md';
import { BsCameraReels, BsFillPatchExclamationFill, BsPersonGear } from 'react-icons/bs';
import { CiBeaker1 } from 'react-icons/ci';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getIncidentCount, getCameraCount, getPromptCount } from '../../actions';
import { Badge } from 'reactstrap';


function SiteSidebar(props) {
    const { portfolioId, siteId } = useParams();
    
    const [ incidentCount, setIncidentCount ] = useState( 0 );
    const [ cameraCount, setCameraCount ] = useState( 0 );
    const [ promptCount, setPromptCount ] = useState( 0 );
    const [ collapsed, setCollapsed ] = useState( false );


    useEffect( () => {
        handleGet();
    }, [] );
    
    const handleGet = () => {
        
      getIncidentCount( portfolioId, siteId, { status: 'open' }, ( err, res ) => {
        if( !err ) {
          setIncidentCount( res.data.count );
        }
      });

      getCameraCount( portfolioId, siteId, { enabled: 1 }, ( err, res ) => {
        if( !err ) {
          setCameraCount( res.data.count );
        }
      });

      getPromptCount( portfolioId, siteId, { enabled: 1 }, ( err, res ) => {
        if( !err ) {
          setPromptCount( res.data.count );
        }
      });

    };
    

    return (
        <Sidebar collapsed={collapsed}>
            <Menu>
                {collapsed && <MenuItem onClick={ () => setCollapsed( false ) } icon={<MdArrowRight />}> Expand</MenuItem>}
                {!collapsed && <MenuItem onClick={ () => setCollapsed( true ) } icon={<MdArrowLeft />}> Collapse</MenuItem>}
                {/*<SubMenu icon={<MdTableChart />} label="Reports" open={true}>*/}
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/reports/count'} />} icon={<MdBarChart />}> Object Count Report</MenuItem>
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/reports/timeline?interval=day'} />} icon={<MdCalendarToday />}> Daily Report</MenuItem>
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/reports/timeline?interval=hour&timeblock=' + ( (new Date()).toISOString().split('T')[0] )} />} icon={<MdTimer />}> Hourly Report</MenuItem>
                <hr />
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors'} />} icon={<BsPersonGear />}> Vendors</MenuItem>
                <hr />
                {/*</SubMenu>*/}
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId} />} icon={<MdHome />}> Site </MenuItem>
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/incidents'} />} icon={<BsFillPatchExclamationFill />} suffix={<Badge>{incidentCount}</Badge>}> Incidents </MenuItem>
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/contacts'} />} icon={<MdPeople />}> Contacts </MenuItem>
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs'} />} icon={<FaRunning />}> Runs </MenuItem>
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/cameras'} />} icon={<MdCameraAlt />} suffix={<Badge>{cameraCount}</Badge>}> Cameras </MenuItem>
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/snapshots'} />} icon={<BsCameraReels />}> Snapshots </MenuItem>
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts'} />} icon={<MdSearch />} suffix={<Badge>{promptCount}</Badge>}> Prompts </MenuItem>
                {/*<MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/tests'} />} icon={<CiBeaker1 />}> Tests </MenuItem>*/}
                <hr />
                <MenuItem component={<Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/settings'} />} icon={<MdSettings />}> Site Settings </MenuItem>
            </Menu>
        </Sidebar>
    );
}


export default SiteSidebar;
