import { useState, useEffect, useRef } from 'react';
import Header from './../../components/header';
import { Table, Row, Col, Form, Button, Input, FormGroup, Label, Container, ListGroup, ListGroupItem, Badge, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { getRuns, getRunsStatus, stopRuns } from '../../actions';

let interval;

function RunsStream({ portfolioId, siteId, cameraId, promptId }) {

  const navigate = useNavigate();
  const location = useLocation();
  
  const [ runs, setRuns ] = useState( [] );
  const [ status, setStatus ] = useState( 'open' );
  //const [ streaming, setStreaming ] = useState( true );
  const [ refreshing, setRefreshing ] = useState( false );
  const [ runsStatus, setRunsStatus ] = useState( {} );

  //const streamingRef = useRef( streaming );

  //useEffect( () => {
  //  streamingRef.current = streaming;
  //}, [streaming] );


  useEffect( () => {
    //clearInterval( interval );
    //interval = setInterval( () => handleGet(), 5000 );
    //return () => {
    //  clearInterval( interval );
    //};
  }, [] );

  useEffect( () => {
    const queryParams = new URLSearchParams(location.search);
    const nextStatus = queryParams.get('status');
    if( nextStatus !== status ) setStatus( nextStatus );
  }, [location] );

  useEffect( () => {
    handleGet();
  }, [portfolioId, siteId] );

  //useEffect( () => {
  //  console.log( 'streaming changed to ', streaming );
  //}, [streaming] );


  function handleGet() {
    //console.log( 'streaming: ', streamingRef.current );
    //if( !streamingRef.current ) return;
      
    setRefreshing( true );
    getRuns( portfolioId, siteId, { cameraId, promptId }, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setRuns( res.data );
        setRefreshing( false );
      }
    });

    /*getRunsStatus( portfolioId, siteId, {}, ( err, res ) => {
      console.log( err, res );
      if( !err ) {
        setRunsStatus( res.data );
      }
    });*/
  };

  const handleStopRuns = () => {
    //stopRuns( portfolioId, siteId, {}, ( err, res ) => {
    //  console.log( err, res );
    //});
  };


  function handleStreaming() {
    /*if( streaming ) {
        setStreaming( false );
        clearInterval( interval );
    } else {
        setStreaming( true );
        interval = setInterval( () => handleGet(), 5000 );
    }*/
  };

  return (
    <div>
      <h3>Runs</h3>
      Cameras: {runsStatus.num_cameras}<br />
      Prompts: {runsStatus.num_prompts}<br />
      Camera idx: {runsStatus.camera_idx}<br />
      Prompt idx: {runsStatus.prompt_idx}<br />
      {/*<Button onClick={ () => handleStopRuns() }>Stop Run</Button>
      {' '}
      <Button onClick={ () => handleStreaming() }>{streaming ? 'Pause' : 'Play'}</Button>
      {streaming ? 'true' : 'false'}
  {refreshing && <Spinner></Spinner>*/}
      <Table>
          <tr>
            <td>ID</td>
            <td>Prompt</td>
            <td>Response</td>
            <td>Probability</td>
            <td>Threshold</td>
            <td>Triggered</td>
            <td>Time</td>
            <td>Camera ID</td>
          </tr>
          {runs.map( run =>
          <tr>
            <td>{run.id}<img src={run.imageUrl} className="frame-small" /></td>
            <td><Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs/' + run.id}>{run.prompt}</Link></td>
            <td>{run.response}</td>
            <td>{Math.round( run.probability * 100 )}%</td>
            <td>{Math.round( run.triggerProbability * 100 )}%</td>
            <td>{run.triggered}</td>
            <td>{run.minutesElapsed} minutes ago</td>
            <td>{run.cameraId}</td>
          </tr>
          )}
      </Table>
    </div>
  );
}

export default RunsStream;
