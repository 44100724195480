import { Container, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Header from "../../components/header";
import AdminSidebar from "../admin/sitebar";
import { useEffect, useState } from "react";
import { createUser } from "../../actions";


export default function CreateUser() {

    const [ username, setUsername ] = useState( '' );
    const [ password, setPassword ] = useState( '' );
    const [ confirmPassword, setConfirmPassword ] = useState( '' );
    const [ email, setEmail ] = useState( '' );
    const [ name, setName ] = useState( '' );

    const handleSubmit = () => {
        createUser( username, password, confirmPassword, email, name, ( err, res ) => {
            console.log( err, res );
        } );
    };

    return (
        <div className="App">
            <Header />
            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                <AdminSidebar />
                <main className="w-100 p-3">        
                    <Container>
                        <h3>Add User</h3>
                        <hr />

                        <Form>

                            <FormGroup>
                                <Label>Username</Label>
                                <Input type="text" value={username} onChange={ e => setUsername( e.target.value ) } />
                            </FormGroup>
                            <FormGroup>
                                <Label>Password</Label>
                                <Input type="password" value={password} onChange={ e => setPassword( e.target.value ) } />
                            </FormGroup>
                            <FormGroup>
                                <Label>Confirm password</Label>
                                <Input type="password" value={confirmPassword} onChange={ e => setConfirmPassword( e.target.value ) } />
                            </FormGroup>
                            <FormGroup>
                                <Label>E-mail</Label>
                                <Input type="text" value={email} onChange={ e => setEmail( e.target.value ) } />
                            </FormGroup>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input type="name" value={name} onChange={ e => setName( e.target.value ) } />
                            </FormGroup>
                            <FormGroup>
                                <Button onClick={handleSubmit}>Add User</Button>
                            </FormGroup>
                        </Form>

                    </Container>
                </main>
            </div>
        </div>
    );
}
