import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Col, Form, Button, Input, FormGroup, Label, Container, ListGroup, ListGroupItem, Row, Spinner } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getPortfolio, patchPortfolio } from '../../actions';
import EditableLabel from '../../components/editableLabel';
import { MdAdd, MdSettings } from 'react-icons/md';


function Portfolio() {

  const navigate = useNavigate();
  const { portfolioId } = useParams();

  const [ name, setName ] = useState( '' );
  const [ sites, setSites ] = useState( [] );
  const [ contacts, setContacts ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );

  useEffect( () => {
    handleGet();
  }, [] );

  const handleGet = () => {
    console.log( portfolioId );
    setIsLoading( true );
    getPortfolio( portfolioId, ( err, res ) => {
      setIsLoading( false );
        console.log( err, res );
        if( !err ) {
            setName( res.data.name );
            setSites( res.data.sites );
        }
    });
  };


  const handleUpdate = patch => {
    patchPortfolio( portfolioId, patch, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
          handleGet();
            //setName( res.data.name );
            //setWorkerUrl( res.data.workerUrl );
            //setSites( res.data.sites );
        }
    });
  };


  return (
    <div className="App">
      <Header />

      <Container>

        <Row className="mb-4">
          <Col xs={6}>
            <h3>Portfolio: {name}</h3>
          </Col>
          <Col xs={6} className="text-end">
            <Link to={'/portfolios/' + portfolioId + '/settings'}><Button className="me-2" size="sm" outline><MdSettings /> Portfolio Settings</Button></Link>
          </Col>
        </Row>

        {/*<Form className="border p-3 mb-3 mt-3">
          <div className="row">
            <Label sm={2}>Name</Label>
            <Col sm={10}>
              <EditableLabel 
                value={name}
                onSave={ value => handleUpdate({ name: value }) }
              />
            </Col>
          </div>
        </Form>*/}

        <Form>

          <FormGroup>
            <ListGroup>
              {isLoading &&
                <ListGroupItem>
                  <Spinner />
                </ListGroupItem>
              }
              {sites.map( site =>
                <ListGroupItem>
                  <Link to={'/portfolios/' + portfolioId + '/sites/' + site.id}>{site.name}</Link>
                </ListGroupItem>
              )}
              <ListGroupItem>
                <Link to={'/portfolios/' + portfolioId + '/sites/create'}><Button size="sm" outline><MdAdd /> Add Site</Button></Link>
              </ListGroupItem>
            </ListGroup>
          </FormGroup>


        </Form>

      </Container>
      
    </div>
  );
}

export default Portfolio;
