import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Form, Button, Input, FormGroup, Label, Container, FormFeedback, FormText } from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { createPrompt } from '../../actions';
import SiteSidebar from '../../components/siteSidebar';


function CreatePrompt() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();

  const [ name, setName ] = useState( '' );
  const [ method, setMethod ] = useState( 'llava' );
  const [ prompt, setPrompt ] = useState( '' );
  const [ trigger, setTrigger ] = useState( 'yes' );
  const [ triggerProbability, setTriggerProbability ] = useState( 0.8 );
  const [ interval, setInterval ] = useState( 60 );
  const [ cameraId, setCameraId ] = useState( -1 );
    //cursor.execute('''CREATE TABLE IF NOT EXISTS prompts
    //(id INTEGER PRIMARY KEY, name TEXT, prompt TEXT, trigger TEXT, trigger_probability FLOAT, interval INTEGER, cameraId INTEGER)''')


    const handleCreate = () => {
      createPrompt( portfolioId, siteId, { name, method, prompt, trigger, triggerProbability, interval, cameraId, enabled: 1 }, ( err, res ) => {
        console.log( err, res );
        if( res.status === 200 ) {
          navigate( '/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + res.data.promptId );
        }
      });
    }  

  return (
    <div className="App">
      <Header />
      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <SiteSidebar />
        <main className="w-100 p-3">

          <Container>
            <h3>Create a new prompt</h3>
            <Form>
            <FormGroup>
                <Label>Name</Label>
                <Input value={name} onChange={ e => setName( e.target.value ) } />
                <FormText>
                  A name for this prompt.<br />
                  For example: Litter, skateboarders, fire.
                </FormText>
              </FormGroup>

              <FormGroup tag="fieldset">
                <FormGroup>
                  <Label>
                    Inference method
                  </Label>
                  <FormText>
                    <br />Select the type of inference to run against the camera stream.
                    Different types of detection objectives, camera image contents and camera image quality may perform better or worse with difference types of inference methods.
                  </FormText>
                </FormGroup>
                <FormGroup check>
                  <Input
                    name="method"
                    type="radio"
                    checked={method === 'itm'}
                    onClick={ e => setMethod( 'itm' ) }
                  />
                  {' '}
                  <Label check>
                    Image-text matching
                  </Label>
                  <FormText>
                    <br />Write a statement about the camera stream and receive a probability score that the statement is true.<br />
                    For example, "There are skateboarders".
                  </FormText>
                </FormGroup>
                <FormGroup check>
                  <Input
                    name="method"
                    type="radio"
                    checked={method === 'vqa'}
                    onClick={ e => setMethod( 'vqa' ) }
                  />
                  {' '}
                  <Label check>
                    Visual question and answer ("yes" or "no" only)
                  </Label>
                  <FormText>
                    <br />Ask a question about the camera stream and receive a "yes" or "no" answer.<br />
                    For example, "Are there skateboarders?"
                  </FormText>
                </FormGroup>
                <FormGroup check>
                  <Input
                    name="method"
                    type="radio"
                    checked={method === 'llava'}
                    onClick={ e => setMethod( 'llava' ) }
                  />
                  {' '}
                  <Label check>
                    LLaVA ("yes" or "no" only)
                  </Label>
                  <FormText>
                    <br />Ask a question about the camera stream and receive a "yes" or "no" answer.<br />
                    For example, "Are there skateboarders?"
                  </FormText>
                </FormGroup>
                <FormGroup check>
                    <Input
                      name="method"
                      type="radio"
                      checked={method === 'sa2-clip'}
                      onClick={ e => setMethod( 'sa2-clip' ) }
                    />
                    {' '}
                    <Label check>
                      Segment Anything 2 and OpenCLIP
                    </Label>
                    <FormText>
                      <br />Identify the number of objects within an image. For example, "a police vehicle" or "skateboarder".
                    </FormText>
                </FormGroup>
              </FormGroup>

              <FormGroup>
                <Label>Prompt</Label>
                <Input value={prompt} onChange={ e => setPrompt( e.target.value ) } />
                <FormText>
                  The question you would like to ask about the image being shown in the video camera.<br />
                  For example: Are there skateboarders?
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label>Trigger</Label>
                <Input value={trigger} onChange={ e => setTrigger( e.target.value ) } />
                <FormText>
                  The AI answer that would trigger the event. 
                  Currently, the only allowed triggers are "yes" or "no".
                </FormText>
              </FormGroup>

              <FormGroup>
                <Label for="triggerProbability">
                Trigger Probability
                </Label>
                <Input
                  id="triggerProbability"
                  name="range"
                  type="range"
                  value={triggerProbability * 100} 
                  onChange={ e => setTriggerProbability( e.target.value / 100 ) }
                />
                <Input value={triggerProbability} onChange={ e => setTriggerProbability( e.target.value ) } />
                <FormText>
                  The probability that the AI is correct in answering the question.
                  More accurately, this is the confidence threshold at which the event is triggered.
                  A percentage of 80% is recommended but can be adjusted up or down to finetune for false positives and false negatives.
                </FormText>
              </FormGroup>

              <FormGroup>
                <Label>Interval</Label>
                <Input value={interval} onChange={ e => setInterval( e.target.value ) } />
                <FormText>
                  The interval at which this prompt will run in terms of seconds.
                  An interval of 60 means that the service will attempt to run this prompt every 60 seconds.
                </FormText>
              </FormGroup>
              {/*<FormGroup>
                <Label>Camera ID</Label>
                <Input value={cameraId} onChange={ e => setCameraId( e.target.value ) } />
              </FormGroup>*/}
            </Form>

            <Button onClick={handleCreate}>Create new prompt</Button>

          </Container>
          

        </main>
      </div>


    </div>
  );
}

export default CreatePrompt;
