import React, { useState, useEffect } from 'react';
import { MdTaskAlt, MdHome, MdLogin, MdLogout, MdSettings, MdWebStories } from 'react-icons/md';
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { Container, Breadcrumb as BreadcrumbComponent, BreadcrumbItem } from 'reactstrap';

function Breadcrumb() {
  
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;
  const pathParts = currentPath.split( '/' ).slice( 1 );


  function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  let displayParts = [];
  for( let p = 0; p < pathParts.length; p++ ) {
    if( isNumeric( pathParts[p] ) ) continue; 
    let linkPath = '/';
    for( let n = 0; n <= p; n++ ) {
      linkPath += pathParts[n] + '/';
    }
    displayParts.push({ name: pathParts[p], path: linkPath });
  }  

  return (
    <Container className="mt-2 mb-2">
      <BreadcrumbComponent listTag="div">
        <BreadcrumbItem href="/" tag="a">Home</BreadcrumbItem>

        {displayParts.map( part => {
            return (<BreadcrumbItem href={part.path} tag="a">{part.name}</BreadcrumbItem>);
        })}
      </BreadcrumbComponent>
    </Container>
  );
}

export default Breadcrumb;
