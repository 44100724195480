import { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaRunning } from 'react-icons';
import { MdCamera, MdCameraAlt, MdHome, MdPeople, MdSearch, MdSettings } from 'react-icons/md';
import { BsFillPatchExclamationFill } from 'react-icons/bs';
import { CiBeaker1 } from 'react-icons/ci';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getIncidentCount, getCameraCount, getPromptCount } from '../../actions';
import { Badge } from 'reactstrap';


function AdminSidebar(props) {
    
    useEffect( () => {
        handleGet();
    }, [] );
    
    const handleGet = () => {
        
    };
    

    return (
        <Sidebar>
            <Menu>                
                <MenuItem component={<Link to={'/admin/users'} />} icon={<MdPeople />}> Users </MenuItem>
            </Menu>
        </Sidebar>
    );
}


export default AdminSidebar;
