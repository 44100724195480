import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Form, Button, Input, FormGroup, Label, Container, ListGroup, ListGroupItem } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getPortfolio } from '../../actions';


function Edit() {

  const navigate = useNavigate();
  const { portfolioId } = useParams();

  const [ name, setName ] = useState( '' );
  const [ sites, setSites ] = useState( [] );

  useEffect( () => {
    handleGet();
  }, [] );

  const handleGet = () => {
    console.log( portfolioId );
    getPortfolio( portfolioId, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
            setName( res.data.name );
            setSites( res.data.sites );
        }
    });
  };


  return (
    <div className="App">
      <Header />

      <Container>
        <Form>
          <FormGroup>
            <Label>Name</Label>
            <Input value={name} onChange={ e => setName( e.target.value ) } />
          </FormGroup>

          <FormGroup>
            <Label>Site(s)</Label>
            <ListGroup>
              {sites.map( site =>
                <ListGroupItem>
                  {site.name}
                </ListGroupItem>
              )}
            </ListGroup>
          </FormGroup>
        </Form>

      </Container>
      
    </div>
  );
}

export default Edit;
